import { FC, Fragment } from "react"
import { Link } from "react-router-dom"
import { FeaturesMenuInterface } from "src/components/layout/headerNavigation"
import { IconButton } from "src/components/ui/IconButton"
import './index.less'
import { Button } from "antd"
import { GlobalRoutes, UserRoutes } from "src/pages/routesEums"
import NotificationCounter from "src/components/ui/NotificationCounter"

type Props = {
  features: FeaturesMenuInterface[]
  comparisonItemsLength?: number
  newMessagesCount?: number
}

export const FeaturesNavigation: FC<Props> = ({ 
  features,
  comparisonItemsLength,
  newMessagesCount 
}) => {

  const getCounter = (path: string) => {
    switch (path) {
      case GlobalRoutes.Comparison:
        return comparisonItemsLength?.toString()
      case UserRoutes.Messages:
        return newMessagesCount?.toString()
      default:
        return null
    }
  }

  
  return (
    <div className={'features-navigation'}>
      {features?.map(feature => {
        const counterToShow = getCounter(feature.href || '')

        return (
          <Fragment key={feature.id}>
            {!feature.href 
              ? <button style={{ border: 'none', background: 'none' }}>
                  {feature.child}
                </button>
              : <Link to={feature.href} state={{ from: location.pathname }}>
                  <div style={{ position: 'relative' }} className={'link'}>
                    <IconButton className={'icon-button'} iconType={feature.icon as any} />
                    <NotificationCounter count={Number(counterToShow)} />
                  </div>
                </Link>
              }
          </Fragment>
        )
      })}
    </div>
  )
}