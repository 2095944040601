import { FC } from "react"

import { ReactComponent as LogoImage } from "./Logo.svg"
import { Link, LinkProps } from "react-router-dom"
import { GlobalRoutes } from "src/pages/routesEums"

type Props = {
  classname?: string
  svgProps?: React.SVGAttributes<SVGSVGElement>
  linkProps?:  React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>
  onClick?: () => void
}

const Logo: FC<Props> = ({ svgProps, linkProps, onClick }) => {
  return (
    <Link 
      onClick={onClick}
      to={GlobalRoutes.Home} 
      aria-roledescription={'logo'} 
      aria-label={'admirie-logo'}
      {...linkProps} 
    >
      <LogoImage 
        height={svgProps?.height ? svgProps?.height : 40}  
        width={svgProps?.width ? svgProps?.width : 120}
        {...svgProps} />
    </Link>
  )
}

export { Logo }
