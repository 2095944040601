import { FC, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChatQuery, useGetAllChatsQuery, useUpdateMessageMutation } from "../../../../graphql"
import { MessageCard } from "../../../elements"
import { VSpace } from "../../../ui/VSpace/VSpace"
import { useParams } from "react-router"
import { useAuth } from "src/context/AuthProvider"

export const MessagesForClients: FC = () => {
  const { user } = useAuth()

  const searchParams = useSearchParams();
  const { id } = useParams()

  const chat = useChatQuery({ variables: {id: id ?? ''}})
  const [updateMessage] = useUpdateMessageMutation()

  const searchValue = searchParams[0]?.get('search') ?? ''

  const allChats = useGetAllChatsQuery({
    variables: {
      filters: {
        client: {id: { eq: user?.id,}},
        and: [{
          agency: {
            agency: {
              or: [
                {agencyName: { contains: searchValue },},
                {agencyAddress: { contains: searchValue },},
              ]
            }
          }
        }],
      },
    },
    fetchPolicy: "network-only",
    pollInterval: 4000
  })

  useEffect(() => {
    chat?.data?.chat?.data?.attributes?.messages?.data?.map(message => {
      if (message?.attributes?.sender?.data?.id !== user?.id) {
        updateMessage({
          variables: {
            id: message?.id ?? '',
            data: {
              isViewed: true,
              isNewMessage: false,
            }
          }
        })
      }
    })
  }, [chat?.data?.chat?.data?.attributes?.messages?.data, user?.id])

  return (
    <VSpace size={32}>
      {allChats?.data?.chats?.data?.map(props => {
        return (
          <MessageCard
            key={props?.id}
            linkTo={`/messages/${props?.id}`}
            avatarSrc={props?.attributes?.agency?.data?.attributes?.agency?.data?.attributes?.agencyLogo?.data?.attributes?.url ?? ''}
            title={props?.attributes?.agency?.data?.attributes?.agency?.data?.attributes?.agencyName ?? ''}
            text={props?.attributes?.agency?.data?.attributes?.agency?.data?.attributes?.agencyAddress ?? ''}
            isOnline={props?.attributes?.agency?.data?.attributes?.isOnline ?? false}
            newMessagesCount={props?.attributes?.messages?.data?.filter(item => item?.attributes?.isNewMessage && item?.attributes?.sender?.data?.id !== user?.id)?.length ?? 0}
          />
        )
      })}
    </VSpace>
  )
}
