import { GlobalRoutes } from "src/pages/routesEums"

const footerLinkGroups = [
  { 
    key: '0', 
    title: '', 
    links: [
      { label: 'UK Property for sale', url: GlobalRoutes.BuyProperties },
      { label: 'UK Property for rent', url: GlobalRoutes.RentProperties },
      { label: 'Locate UK agents', url: GlobalRoutes.Agencies }
    ] 

  },
  { 
    key: '1', 
    title: '', 
    links: [
      { label: 'Blog', url: '#our-blog-section' },
    ] 
  },
]

const socials = [
  { url: 'https://x.com/admiriecom?s=21&t=fNmU82k7YZSX0a0beLQv5A', icon: 'x' },
  { url: 'https://www.instagram.com/admiriecom?igsh=YjlucXMzN3JzdHI1', icon: 'instagram' },
  { url: 'https://www.facebook.com/share/Ed4xH5Ez2UNmx1BJ/?mibextid=LQQJ4d ', icon: 'facebook' },
  { url: 'https://www.tiktok.com/@admiriecom', icon: 'tiktok' }
]

export { footerLinkGroups, socials }