import { ReactNode } from "react"
import { Notifications } from "src/components/notifications"
import { AdminRoutes, AgencyRoutes, GlobalRoutes, UserRoutes } from "src/pages/routesEums"

export interface FeaturesMenuInterface {
  icon: string | JSX.Element,
  activeIcon: string
  id: string
  target?: string
  href?: string
  child?: ReactNode
}


const userDashboardMenu: ComponentUiLink[] = [
  { icon: null, id: '1', target: 'self', title: 'Searches', url: UserRoutes.SavedSearches },
  { icon: null, id: '2', target: 'self', title: 'Properties list', url: UserRoutes.PropertiesLists },
  { icon: null, id: '3', target: 'self', title: 'Requests', url: UserRoutes.RequestView },
  { icon: null, id: '4', target: 'self', title: 'Settings', url: UserRoutes.Settings },
]

const defaultMenu: ComponentUiLink[] = [
  { icon: null, id: '1', target: 'self', title: 'Buy Property', url: GlobalRoutes.BuyProperties },
  { icon: null, id: '2', target: 'self', title: 'Rent Property', url: GlobalRoutes.RentProperties },
  { icon: null, id: '3', target: 'self', title: 'Agencies', url: GlobalRoutes.Agencies },
]

const agencyMenu: ComponentUiLink[] = [
  { icon: null, id: '1', target: 'self', title: 'Dashboard', url: AgencyRoutes.Dashboard },
  { icon: null, id: '2', target: 'self', title: 'Sales Listings', url: AgencyRoutes.Listings },
  { icon: null, id: '3', target: 'self', title: 'Rental Listings', url: AgencyRoutes.RentListings },
  { icon: null, id: '4', target: 'self', title: 'Settings', url: AgencyRoutes.Settings },
]

const adminMenu: ComponentUiLink[] = [
  { icon: null, id: '1', target: 'self', title: 'Dashboard', url: AdminRoutes.Dashboard },
  { icon: null, id: '2', target: 'self', title: 'Users & Permissions', url: AdminRoutes.UsersAndPermissions },
  { icon: null, id: '4', target: 'self', title: 'Listings', url: AdminRoutes.Listings },
  { icon: null, id: '5', target: 'self', title: 'Blog Manager', url: AdminRoutes.BlogManager },
  { icon: null, id: '6', target: 'self', title: 'Settings', url: AdminRoutes.Settings },
] 

const userFeaturesMenu: FeaturesMenuInterface[] = [
  { icon: 'comparison', activeIcon: 'comparison-active', id: '3', target: 'self', href: GlobalRoutes.Comparison },
  { icon: 'message', activeIcon: 'message-active', id: '4', target: 'self', href: UserRoutes.Messages },
  { icon: 'notification', activeIcon: 'notification-active', id: '5', child: <Notifications />},
]

const agencyFeaturesMenu: FeaturesMenuInterface[] = [
  { icon: 'message', activeIcon: 'message-active', id: '4', target: 'self', href: AgencyRoutes.Messages },
  { icon: '', activeIcon: 'notification-active', id: '5', child: <Notifications /> },
]

const adminFeaturesMenu: FeaturesMenuInterface[] = [
  { id: '1', icon: '', activeIcon: 'notification-active', child: <Notifications /> },
]

const navigationMenus = {
  userMenuItems: {
    defaultMenu,
    userDashboardMenu,
  },
  agencyMenuItems: {
    agencyMenu,
  },
  adminMenuItems: {
    adminMenu,
    adminFeaturesMenu
  }
}

const featureMenus = {
  userFeaturesMenu,
  agencyFeaturesMenu,
  adminFeaturesMenu
}


export { navigationMenus, featureMenus }
