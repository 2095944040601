import { FC } from "react";
import { Input, InputProps } from "antd"

import { SvgIcon } from "../../icons/SvgIcon";
import "./SearchInput.less";
import classNames from "classnames"

export const SearchInput: FC<InputProps & {customSize?: 'sm' | string, placeholder?: string}> = ({customSize, placeholder, ...props}) => (
  <Input
    size={'large'}
    prefix={<SvgIcon type={'search'} />}
    placeholder={placeholder ? placeholder : 'Find in Messages'}
    rootClassName={classNames('search-input', {'sm': customSize === 'sm'})}
    {...props}
  />
)
