import { Col, Divider, Row, Typography } from "antd"
import { FC } from "react"
import './Footer.less'
import { Logo } from "src/components/logo"
import { Link } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { footerLinkGroups, socials } from "./data"
import { SvgIcon } from "src/components/icons/SvgIcon"
import { useDocumentsQuery } from "src/graphql"

interface Doc {
  'terms': {
    name: string
    file: string
  }
  'policy': {
    name: string
    file: string
  }
}


const Footer: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' })
    
  const { data } = useDocumentsQuery()

  const docs = data?.documents?.data.reduce((acc, doc) => {
    if (doc.attributes?.name?.toLowerCase().includes('terms')) {
      acc.terms = { name: doc?.attributes.name, file: doc.attributes.file?.data?.attributes?.url || '' };
    } else if (doc?.attributes?.name?.toLowerCase().includes('policy')) {
      acc.policy = { name: doc.attributes?.name, file: doc.attributes.file?.data?.attributes?.url || '' };
    }
    return acc;
  }, { terms: {
    name: '',
    file: ''
  }, policy: {
    name: '',
    file: ''
  } });

  return (
    <div className={'footer-wrapper'}>
      <Row className={'footer-first-row'}>
        <Col span={isDesktop ? 4 : 12} order={isDesktop ? 1 : 2} className={'footer-logo-wrapper'}>
          <Logo classname={'footer-logo'} />
        </Col>

        <Col span={isDesktop ? 6 : 12} order={isDesktop ? 2 : 1} className={'footer-groups-wrapper'}>
          {footerLinkGroups.map((group, index) => (
            <div key={group.key} className={'footer-link-group'}>
              {group.links.map((link, index) => {
                if (link.label.toLowerCase().includes('blog')) {
                  return (
                    <a className={'label-md'} href={link.url} key={link.label}>
                      {link.label}
                    </a>
                  )
                }

                return (
                  <Link className={'label-md group-link'} key={index} to={link.url}>
                    {link.label}
                  </Link>
                )
              })}
            </div>
          ))}
        </Col>
      </Row>
      
      <Divider style={{ maxWidth: '1440px' }} />

      <Row className={'footer-second-row'}>
        <Col order={isDesktop ? 1 : 3} className={'footer-bottom-link paragraph-sm'} >
          <Typography.Text>@ 2024 Admirie</Typography.Text>
        </Col>

        <Col order={isDesktop ? 2 : 1} className={'footer-bottom-link paragraph-sm'}>
          <a 
            target={'_blank'} 
            rel={'noreferrer'} 
            href={docs?.terms.file}
            className={'paragraph-sm'}
          >
            Terms and Conditions
          </a>
        </Col>

        <Col order={isDesktop ? 3 : 2} className={'footer-bottom-link paragraph-sm'}>
          <a 
            target={'_blank'} 
            rel={'noreferrer'} 
            className={'paragraph-sm'}
            href={docs?.policy.file}
          >
            Privacy Policy
          </a>
        </Col>

        <Col order={4} className={'footer-socials-wrapper'}>
          {socials.map((social, index) => (
            <a target={'_blank'} rel={'noreferrer'} href={social.url} key={index}>
              <SvgIcon type={social.icon as any} />
            </a>
          ))}
        </Col>
      </Row>
    </div>
  )
}

export { Footer }
