import { FC, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChatQuery, useGetAllChatsForAgentQuery, useUpdateMessageMutation } from "../../../../graphql"
import { MessageCard } from "../../../elements"
import { VSpace } from "../../../ui/VSpace/VSpace"
import { useParams } from "react-router"
import { useAuth } from "src/context/AuthProvider"

export const MessagesForAgent: FC = () => {
  const { user } = useAuth()

  const searchParams = useSearchParams();
  const searchValue = searchParams[0]?.get('search') ?? ''
  const { id } = useParams()

  const chat = useChatQuery({ variables: {id: id ?? ''}})
  const [updateMessage] = useUpdateMessageMutation()

  useEffect(() => {
    chat?.data?.chat?.data?.attributes?.messages?.data?.map(message => {
      if (message?.attributes?.sender?.data?.id !== user?.id) {
        updateMessage({
          variables: {
            id: message?.id ?? '',
            data: {
              isViewed: true,
              isNewMessage: false,
            }
          }
        })
      }
    })
  }, [chat?.data?.chat?.data?.attributes?.messages?.data, user?.id])

  const allChatsForAgent = useGetAllChatsForAgentQuery(
    {
      variables: {
        filters: {
          agency: {
            id: {eq: user?.id },
          },
          and: [
            {
              client: {
                or: [
                  {firstName: {contains: searchValue}},
                  {lastName: {contains: searchValue}}
                ]
              }
            }
          ]
        }
      }, pollInterval: 4000, fetchPolicy: "network-only"})

  return (
    <VSpace size={32}>
      {allChatsForAgent?.data?.chats?.data?.map(props => (
        <MessageCard
          key={props?.id}
          linkTo={`/messages/${props?.id}`}
          avatarSrc={props?.attributes?.client?.data?.attributes?.avatar?.data?.attributes?.url ?? ''}
          title={`${props?.attributes?.client?.data?.attributes?.firstName ?? ''} ${props?.attributes?.client?.data?.attributes?.lastName ?? ''}`}
          text={''}
          isOnline={props?.attributes?.client?.data?.attributes?.isOnline ?? false}
          newMessagesCount={props?.attributes?.messages?.data?.filter(item => item?.attributes?.sender?.data?.id !== user?.id && item?.attributes?.isNewMessage)?.length ?? 0}
        />
      ))}
    </VSpace>
  )
}
