import { Typography } from "antd";
import { format } from "date-fns";
import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { StatusTag } from "src/components/ui/StatusTag";
import { useUpdateNotificationMutation } from "src/graphql";
import { NotificationsData } from "../../index";
import { useCustomBreakpoint } from "src/hooks/useCustomBreakpoint";

export const RequestStatusUpdate: FC<NotificationsData> = (notification) => {
  const { 
    id,
    isNew,
   } = notification
  
  const [isNewNotification, setIsNewNotification] = useState(isNew)
  const navigate = useNavigate()

  const { isDesktop } = useCustomBreakpoint()

  const [updateNotification] = useUpdateNotificationMutation({
    onCompleted: () => {
      if (notification.refetchNotifications) {
        notification.refetchNotifications()
      }
      setIsNewNotification(false)
    }
  })

  const onUpdate = async () => {
    await updateNotification({
      variables: {
        id: id as string,
        input: {
          isNew: false
        }
      }
    })
  }

  const formatDate = (date: Date) => {
    return format(date, 'dd/MM/yyyy, HH:mm');
  };
  
  const handleNavigate = () => {
    navigate(`/dashboard/request-view`)
    onUpdate()
  }

  const preparedData = {
    status: notification.propertyViewRequest?.attributes?.requestStatus,
    address: notification.propertyViewRequest?.attributes?.property?.data?.attributes?.address1,
    date: notification.date
  }

  const statusColor = preparedData.status === 'approved' ? 'success' : 'red'

  return (
    <div className={'new-request-notification'} onClick={handleNavigate}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Typography.Text className={'label-sm'}>
            Your Request status was updated to
          </Typography.Text>
          <StatusTag text={preparedData.status as string} color={statusColor} />
        </div>


        <div className={'notification-info'}>
          <div>
            <Typography.Text>
              {formatDate(preparedData.date as Date)}
            </Typography.Text>
          </div>

          {isDesktop && <div className={'split-dot'} />}

          {preparedData.address && (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} className={'address-wrapper'}>
              <Typography.Text>
                {`${preparedData.address}`}
              </Typography.Text>

                {!isDesktop && isNewNotification && <div>
                  <StatusTag text={'new'} color={'success'} />
                </div>}
            </div>
          )}
        </div>
      </div>

      {isDesktop && isNewNotification && <div>
        <StatusTag text={'new'} color={'success'} />
      </div>}
    </div>
  )
}