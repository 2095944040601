import { Typography } from "antd"
import { format } from "date-fns"
import { CSSProperties, FC, useState } from "react"
import { useNavigate } from "react-router"
import { StatusTag } from "src/components/ui/StatusTag"
import { useUpdateNotificationMutation } from "src/graphql"
import { NotificationsData } from "../.."
import './index.less'
import { useCustomBreakpoint } from "src/hooks/useCustomBreakpoint"

export const NewRequestNotification: FC<Partial<NotificationsData>> = (notification) => {
  const [isNewNotification, setIsNewNotification] = useState(notification.isNew)
  const navigate = useNavigate()

  const { isDesktop } = useCustomBreakpoint()

  const [updateNotification] = useUpdateNotificationMutation({
    onCompleted: () => {
      if (notification.refetchNotifications) {
        notification.refetchNotifications()
      }
      setIsNewNotification(false)
    }
  })

  const preparedData = {
    propertyId: notification.propertyViewRequest?.attributes?.property?.data?.id,
    properyAddress: notification.propertyViewRequest?.attributes?.property?.data?.attributes?.address1,
    initiatorFirstName: notification.propertyViewRequest?.attributes?.users_permissions_user?.data?.attributes?.firstName,
    inititatorLastName: notification.propertyViewRequest?.attributes?.users_permissions_user?.data?.attributes?.lastName,
    date: notification.date
  }

  const onUpdate = async () => {
    await updateNotification({
      variables: {
        id: notification.id as string,
        input: {
          isNew: false
        }
      }
    })
  }

  const formatDate = (date: Date) => {
    return format(date, 'dd/MM/yyyy, HH:mm');
  };
  
  const handleNavigate = () => {
    navigate(`/request-view/${preparedData.propertyId}?activeTab=waiting`)
    onUpdate()
  }

  const mobileStyles: CSSProperties = {
    display: 'flex', 
    width: '100%', 
    justifyContent: 'space-between'
  }

  return (
    <div className={'new-request-notification'} onClick={handleNavigate}>
      <div>
        {isDesktop 
          ? <div>
              <Typography.Text className={'label-sm'}>
                {`View Request from ${preparedData.initiatorFirstName} ${preparedData.inititatorLastName}`}
              </Typography.Text>
            </div>
          : <div className={'mobile-heading'}>
              <Typography.Text className={'label-sm'}>
                {"View Request from"}
              </Typography.Text>

              <Typography.Text className={'label-sm'}>
                {`${preparedData.initiatorFirstName} ${preparedData.inititatorLastName}`}
              </Typography.Text>
            </div>
        }
        <div className={'notification-info'}>
          <div className={'date'}>
            <Typography.Text >
              {formatDate(preparedData.date as Date)}
            </Typography.Text>
          </div>

          {isDesktop && <div className={'split-dot'} />}

          {preparedData?.properyAddress && (
            <div className={'address-wrapper'}>
              <Typography.Text>
                {`${preparedData.properyAddress}`}
              </Typography.Text>

              {!isDesktop && isNewNotification && <div>
                <StatusTag text={'new'} color={'success'} />
              </div>}
            </div>
          )}
        </div>
      </div>

      {isDesktop && isNewNotification && <div>
        <StatusTag text={'new'} color={'success'} />
      </div>}
    </div>
  )
}