import { FC } from "react"
import './index.less'
import { Loader } from "src/components/layout/Loader"

export const PageLoader: FC = () => {
  return (
    <div className={'page-loader'}>
      <Loader size={'large'} spinning />
    </div>
  )
}