import { FC, Suspense, lazy, useEffect, useState } from "react"
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom"

import DefaultLayout, { Loader } from "../components/layout/default"
import { AuthLayout } from "src/components/layout/auth/AuthLayout"
import { FooterlessLayout } from "src/components/layout/footerless/FooterlessLayout"
import { MessagesLayout } from "../components/layout/messages/MessagesLayout"
import { useAuth } from "src/context/AuthProvider"
import { AdminRoutes, AgencyRoutes, GlobalRoutes, UserRoutes } from "./routesEums"
import { PageLoader } from "./PageLoader"

const commonPages = {
  SignUp: lazy<FC>(() => import("./auth/sign-up")),
  SignIn: lazy<FC>(() => import("./auth/sign-in")),
  ForgotPassword: lazy<FC>(() => import("./auth/forgot-password")),
  ResetPassword: lazy<FC>(() => import("./auth/reset-password")),
  ErrorPage: lazy<FC>(() => import("./error-page"))
}

const agencyPages = {
  Dashboard: lazy<FC>(() => import("./agency-pages/dashboard")),
  BuyProperties: lazy<FC>(() => import('./user-pages/buy-property')),
  Home: lazy<FC>(() => import("./user-pages/home")),
  Listings: lazy<FC>(() => import("./agency-pages/listings")),
  RentListings: lazy<FC>(() => import("./agency-pages/rent-listings")),
  Messages: lazy<FC>(() => import('./agency-pages/messages')),
  Chat: lazy<FC>(() => import('./agency-pages/chat')),
  RequestView: lazy<FC>(() => import('./agency-pages/request-view')),
  Settings: lazy<FC>(() => import('./agency-pages/settings')),
}

const userPages = {
  Home: lazy<FC>(() => import("./user-pages/home")),
  BuyProperties: lazy<FC>(() => import('./user-pages/buy-property')),
  RentProperties: lazy<FC>(() => import('./user-pages/rent-property')),
  Agencies: lazy<FC>(() => import('./user-pages/agencies')),
  Agency: lazy<FC>(() => import('./user-pages/agency')),
  Property: lazy<FC>(() => import('./user-pages/property')),
  Comparison: lazy<FC>(() => import('./user-pages/comparison')),
  SavedSearches: lazy<FC>(() => import('./user-pages/saved-searches')),
  PropertiesLists: lazy<FC>(() => import('./user-pages/properties-lists')),
  PropertiesList: lazy<FC>(() => import('./user-pages/properties-list')),
  Chat: lazy<FC>(() => import('./user-pages/chat')),
  Settings: lazy<FC>(() => import('./user-pages/settings')),
  Messages: lazy<FC>(() => import('./user-pages/messages')),
  RequestView: lazy<FC>(() => import('./user-pages/request-view')),
  Blog: lazy<FC>(() => import('./user-pages/blog')),
}

const adminPages = {
  Dashboard: lazy<FC>(() => import('./admin-pages/dashboard')),
  UsersAndPermissions: lazy<FC>(() => import('./admin-pages/users-and-permissions')),
  Listings: lazy<FC>(() => import('./admin-pages/listings')),
  BlogManager: lazy<FC>(() => import('./admin-pages/blog-manager')),
  Settings: lazy<FC>(() => import('./admin-pages/settings')),
}

const globalRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: userPages.Home,
        path: GlobalRoutes.Home,
        index: true,
      },
      {
        Component: userPages.Agencies,
        path: GlobalRoutes.Agencies,
        index: true
      },
      {
        Component: userPages.Agency,
        path: GlobalRoutes.Agency,
        index: true
      },
      {
        Component: userPages.BuyProperties,
        path: GlobalRoutes.BuyProperties,
        index: true,
      },
      {
        Component: userPages.RentProperties,
        path: GlobalRoutes.RentProperties,
        index: true
      },
      {
        Component: userPages.Blog,
        path: GlobalRoutes.Blog,
        index: true,
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
  {
    Component: FooterlessLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: userPages.Comparison,
        path: GlobalRoutes.Comparison
      },
      {
        Component: userPages.Property,
        path: GlobalRoutes.RentProperty,
      },
      {
        Component: userPages.Property,
        path: GlobalRoutes.BuyProperty,
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
  {
    Component: AuthLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: commonPages.SignUp,
        path: GlobalRoutes.SignUp,
      },
      {
        Component: commonPages.SignIn,
        path: GlobalRoutes.SignIn
      },
      {
        Component: commonPages.ForgotPassword,
        path: GlobalRoutes.ForgotPassword
      },
      {
        Component: commonPages.ResetPassword,
        path: GlobalRoutes.ResetPassword
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
]

const adminRoutes: RouteObject[] = [
  {
    Component: FooterlessLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: adminPages.UsersAndPermissions,
        path: AdminRoutes.UsersAndPermissions
      },
      {
        Component: adminPages.Dashboard,
        path: AdminRoutes.Dashboard,
      },
      {
        Component: adminPages.Listings,
        path: AdminRoutes.Listings
      },
      {
        Component: adminPages.Settings,
        path: AdminRoutes.Settings
      },
      {
        Component: adminPages.BlogManager,
        path: AdminRoutes.BlogManager
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
  {
    Component: commonPages.ErrorPage,
    path: "*",
  },
]

const agencyRoutes: RouteObject[] = [
  {
    Component: FooterlessLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: agencyPages.Home,
        path: GlobalRoutes.Home,
      },
      {
        Component: agencyPages.Dashboard,
        path: AgencyRoutes.Dashboard,
      },
      {
        Component: agencyPages.Listings,
        path: AgencyRoutes.Listings
      },
      {
        Component: agencyPages.RentListings,
        path: AgencyRoutes.RentListings
      },
      {
        Component: agencyPages.Settings,
        path: AgencyRoutes.Settings
      },
      {
        Component: agencyPages.RequestView,
        path: AgencyRoutes.RequestView
      },
      {
        Component: agencyPages.RequestView,
        path: AgencyRoutes.RequestView
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
  {
    Component: MessagesLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: agencyPages.Messages,
        path: AgencyRoutes.Messages,
      },
      {
        Component: agencyPages.Chat,
        path: AgencyRoutes.Message,
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
  {
    Component: commonPages.ErrorPage,
    path: '*'
  }
]

const userRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: userPages.SavedSearches,
        path: UserRoutes.SavedSearches
      },
      {
        Component: userPages.PropertiesLists,
        path: UserRoutes.PropertiesLists,
      },
      {
        Component: userPages.PropertiesList,
        path: UserRoutes.PropertiesList
      },
      {
        Component: userPages.RequestView,
        path: UserRoutes.RequestView
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ],
  },
  {
    Component: FooterlessLayout,
    children: [
      {
        Component: userPages.Comparison,
        path: GlobalRoutes.Comparison
      },
      {
        Component: userPages.Settings,
        path: UserRoutes.Settings
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
  {
    Component: AuthLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: commonPages.SignUp,
        path: GlobalRoutes.SignUp,
      },
      {
        Component: commonPages.SignIn,
        path: GlobalRoutes.SignIn
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
  {
    Component: MessagesLayout,
    loader: () => <PageLoader />,
    children: [
      {
        Component: userPages.Messages,
        path: UserRoutes.Messages,
      },
      {
        Component: userPages.Chat,
        path: UserRoutes.Message,
      },
      {
        Component: commonPages.ErrorPage,
        path: '*'
      }
    ]
  },
]

const Router: FC = () => {
  const { user, role: roleName, loading } = useAuth()
  const [routes, setRoutes] = useState<RouteObject[]>([...globalRoutes]);

  useEffect(() => {
    const role = roleName?.toLowerCase();
  
    switch (role) {
      case 'agency':
        setRoutes([...agencyRoutes, ...globalRoutes]);
        break;
    
      case 'user':
        setRoutes([...userRoutes, ...globalRoutes]);
        break;
    
      case 'owner':
      case 'admin':
        setRoutes([...adminRoutes, ...globalRoutes]);
        break;
    
      default:
        setRoutes([...globalRoutes]);
        break;
    }
  }, [roleName]);

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />

}

export default Router;

