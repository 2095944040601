import { Layout } from "antd";
import { FC, Suspense } from "react";
import { Outlet } from "react-router";
import { Header } from "./Header";

const AuthLayout: FC = () => (
  <Layout style={{ height: '100vh' }}>
    <Header />
    <Suspense>
      <Outlet />
    </Suspense>
  </Layout>
)

export { AuthLayout }