import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Agency",
      "AgencyReview",
      "AgencyVerificationRequest",
      "BlogArticle",
      "BlogCategory",
      "BlogSubscriber",
      "BlogTag",
      "Chat",
      "City",
      "ComponentDataAttachedFiles",
      "ComponentDataEntry",
      "ComponentDataNotification",
      "ComponentDataNotificationSetting",
      "ComponentDataSet",
      "ComponentDataSubscriptionPlan",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiList",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSectionWithCards",
      "ComponentUiTab",
      "ComponentUiText",
      "Document",
      "EmailDesignerEmailTemplate",
      "EmptyState",
      "ForgotPassword",
      "Home",
      "Layout",
      "Message",
      "NewMessage",
      "Notification",
      "PlatformReview",
      "Portfolio",
      "Property",
      "PropertyViewRequest",
      "ReactIconsIconlibrary",
      "RefundRequest",
      "RentingDetail",
      "ResetPassword",
      "SignIn",
      "SignupStep",
      "Subscription",
      "SubscriptionPlan",
      "SupportForm",
      "UploadFile",
      "UploadFolder",
      "UserFavoriteList",
      "UserNotificationSetting",
      "UserPropertyView",
      "UserReport",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VerificationInfo"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Agency",
      "AgencyReview",
      "AgencyVerificationRequest",
      "BlogArticle",
      "BlogCategory",
      "BlogSubscriber",
      "BlogTag",
      "Chat",
      "City",
      "ComponentDataAttachedFiles",
      "ComponentDataEntry",
      "ComponentDataNotification",
      "ComponentDataNotificationSetting",
      "ComponentDataSet",
      "ComponentDataSubscriptionPlan",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiList",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSectionWithCards",
      "ComponentUiTab",
      "ComponentUiText",
      "Document",
      "EmailDesignerEmailTemplate",
      "EmptyState",
      "ForgotPassword",
      "Home",
      "Layout",
      "Message",
      "NewMessage",
      "Notification",
      "PlatformReview",
      "Portfolio",
      "Property",
      "PropertyViewRequest",
      "ReactIconsIconlibrary",
      "RefundRequest",
      "RentingDetail",
      "ResetPassword",
      "SignIn",
      "SignupStep",
      "Subscription",
      "SubscriptionPlan",
      "SupportForm",
      "UploadFile",
      "UploadFolder",
      "UserFavoriteList",
      "UserNotificationSetting",
      "UserPropertyView",
      "UserReport",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VerificationInfo"
    ]
  }
};
      export default result;
    
export const SetFragmentDoc = gql`
    fragment Set on ComponentDataSet {
  id
  value
}
    `;
export const PropertyFragmentDoc = gql`
    fragment Property on Property {
  price
  address1
  address2
  description
  uuid
  square
  rooms
  basement
  baths
  bedrooms
  bathrooms
  parking
  wheelchair_access
  fullAddress
  lowercasedFullAddress
  elevator
  rentPrice
  propertyStatus
  dishwasher
  fitness_center
  cinema
  pool
  year_built
  lot_size
  property_type
  createdAt
  status
  lat
  lng
  agency {
    data {
      id
      attributes {
        agencyName
        agencyAddress
        reviewsCount
        phoneNumber
        agent {
          data {
            id
          }
        }
      }
    }
  }
  media {
    data {
      attributes {
        url
      }
    }
  }
  amenity {
    ...Set
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const AgencyReviewFragmentDoc = gql`
    fragment AgencyReview on AgencyReview {
  reviewRating
  reviewText
  users_permissions_user {
    data {
      attributes {
        firstName
        lastName
        avatar {
          data {
            ...File
          }
        }
      }
    }
  }
}
    `;
export const AgencyFragmentDoc = gql`
    fragment Agency on Agency {
  agencyName
  agencyAddress
  aboutUs
  websiteLink
  workingHoursFrom
  workingHoursTo
  phoneNumber
  createdAt
  reviewsCount
  averageRating
  verificationStatus
  properties {
    data {
      id
      attributes {
        ...Property
      }
    }
  }
  agency_reviews(pagination: {limit: -1}) {
    data {
      id
      attributes {
        ...AgencyReview
      }
    }
  }
  agencyLogo {
    data {
      ...File
    }
  }
  slug
  agent {
    data {
      id
      attributes {
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
}
    `;
export const BlogArticleFragmentDoc = gql`
    fragment BlogArticle on BlogArticle {
  title
  shortDescription
  article
  createdAt
  updatedAt
  publishState
  slug
  timeToRead
  publishDate
  blogTags {
    data {
      id
      attributes {
        tagValue
        tagName
      }
    }
  }
  blogCategory {
    data {
      id
      attributes {
        categoryName
      }
    }
  }
  media {
    data {
      ...File
    }
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UsersPermissionsUserEntity {
  id
  attributes {
    firstName
    lastName
    phoneNumber
    avatar {
      data {
        attributes {
          url
        }
      }
    }
    stripeCustomerId
    isAbleToSwitchRole
    agency {
      data {
        id
        attributes {
          agencyName
          agencyAddress
          agencyLogo {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const MessageFragmentDoc = gql`
    fragment Message on MessageEntity {
  id
  attributes {
    sender {
      data {
        ...User
      }
    }
    receiver {
      data {
        ...User
      }
    }
    content
    dateSend
    files {
      url
    }
    chat {
      data {
        id
      }
    }
    isViewed
    isNewMessage
  }
}
    `;
export const ChatFragmentDoc = gql`
    fragment Chat on ChatEntity {
  id
  attributes {
    user {
      data {
        id
      }
    }
    agency {
      data {
        id
        attributes {
          isOnline
          agency {
            data {
              id
              attributes {
                agencyName
                agencyAddress
                agencyLogo {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    client {
      data {
        id
        attributes {
          firstName
          lastName
          isOnline
          avatar {
            data {
              ...File
            }
          }
        }
      }
    }
    messages {
      data {
        ...Message
      }
    }
    viewByAgent
    viewByClient
  }
}
    `;
export const EmptyStateFragmentDoc = gql`
    fragment EmptyState on EmptyStateEntity {
  id
  attributes {
    title
    subtitle
    type
    image {
      data {
        ...File
      }
    }
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const NewMessageFragmentDoc = gql`
    fragment NewMessage on NewMessageEntity {
  id
  attributes {
    from {
      data {
        id
      }
    }
    to {
      data {
        id
      }
    }
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const BlogTagFragmentDoc = gql`
    fragment BlogTag on BlogTagEntity {
  id
  attributes {
    tagName
    tagValue
  }
}
    `;
export const PartialBlogArticleFragmentDoc = gql`
    fragment PartialBlogArticle on BlogArticleEntity {
  id
  attributes {
    title
    shortDescription
    publishState
    publishDate
    createdAt
    media {
      data {
        ...File
      }
    }
    slug
    blogTags {
      data {
        ...BlogTag
      }
    }
  }
}
    `;
export const PlatformReviewFragmentDoc = gql`
    fragment PlatformReview on PlatformReview {
  rating
  reviewText
  user {
    data {
      attributes {
        username
        avatar {
          data {
            ...File
          }
        }
      }
    }
  }
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const SectionWithCardsFragmentDoc = gql`
    fragment SectionWithCards on ComponentUiSectionWithCards {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
  card {
    ...Card
  }
}
    `;
export const SignupStepFragmentDoc = gql`
    fragment SignupStep on SignupStepEntity {
  id
  attributes {
    image {
      data {
        ...File
      }
    }
    commonTitle
    commonDescription
    userRoleTitle
    userRoleDescription
    agencyRoleTitle
    agencyRoleDescription
  }
}
    `;
export const SubscriptionFragmentDoc = gql`
    fragment Subscription on Subscription {
  subscriptionId
  status
  subscriptionStart
  subscriptionEnd
  subscriptionType
  cancelAtPeriodEnd
  createdAt
  user {
    data {
      id
      attributes {
        email
      }
    }
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const UserFavoriteListFragmentDoc = gql`
    fragment UserFavoriteList on UserFavoriteList {
  listName
  properties {
    data {
      id
      attributes {
        bedrooms
        square
        price
        address1
        address2
        createdAt
        media {
          data {
            ...File
          }
        }
      }
    }
  }
}
    `;
export const UserReportFragmentDoc = gql`
    fragment UserReport on UserReport {
  reason
  comment
  processed
  uuid
  createdAt
  reportType
  user {
    data {
      id
    }
  }
  agency {
    data {
      id
      attributes {
        agencyName
        agencyLogo {
          data {
            ...File
          }
        }
      }
    }
  }
  property {
    data {
      id
      attributes {
        address1
        address2
        media {
          data {
            ...File
          }
        }
      }
    }
  }
}
    `;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
  changePassword(
    currentPassword: $currentPassword
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export const ConfirmEmailDocument = gql`
    mutation confirmEmail($confirmation: String!) {
  emailConfirmation(confirmation: $confirmation) {
    jwt
    user {
      id
    }
  }
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export const CreateAgencyDocument = gql`
    mutation createAgency($data: AgencyInput!) {
  createAgency(data: $data) {
    data {
      id
      attributes {
        agencyName
        agencyAddress
        phoneNumber
        workingHours
        websiteLink
      }
    }
  }
}
    `;
export type CreateAgencyMutationFn = Apollo.MutationFunction<CreateAgencyMutation, CreateAgencyMutationVariables>;
export function useCreateAgencyMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgencyMutation, CreateAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgencyMutation, CreateAgencyMutationVariables>(CreateAgencyDocument, options);
      }
export type CreateAgencyMutationHookResult = ReturnType<typeof useCreateAgencyMutation>;
export type CreateAgencyMutationResult = Apollo.MutationResult<CreateAgencyMutation>;
export const CreateAgencyReviewDocument = gql`
    mutation createAgencyReview($data: AgencyReviewInput!) {
  createAgencyReview(data: $data) {
    data {
      id
      attributes {
        reviewText
      }
    }
  }
}
    `;
export type CreateAgencyReviewMutationFn = Apollo.MutationFunction<CreateAgencyReviewMutation, CreateAgencyReviewMutationVariables>;
export function useCreateAgencyReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgencyReviewMutation, CreateAgencyReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgencyReviewMutation, CreateAgencyReviewMutationVariables>(CreateAgencyReviewDocument, options);
      }
export type CreateAgencyReviewMutationHookResult = ReturnType<typeof useCreateAgencyReviewMutation>;
export type CreateAgencyReviewMutationResult = Apollo.MutationResult<CreateAgencyReviewMutation>;
export const CreateAgencyVerificationRequestDocument = gql`
    mutation createAgencyVerificationRequest($data: AgencyVerificationRequestInput!) {
  createAgencyVerificationRequest(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateAgencyVerificationRequestMutationFn = Apollo.MutationFunction<CreateAgencyVerificationRequestMutation, CreateAgencyVerificationRequestMutationVariables>;
export function useCreateAgencyVerificationRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgencyVerificationRequestMutation, CreateAgencyVerificationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgencyVerificationRequestMutation, CreateAgencyVerificationRequestMutationVariables>(CreateAgencyVerificationRequestDocument, options);
      }
export type CreateAgencyVerificationRequestMutationHookResult = ReturnType<typeof useCreateAgencyVerificationRequestMutation>;
export type CreateAgencyVerificationRequestMutationResult = Apollo.MutationResult<CreateAgencyVerificationRequestMutation>;
export const CreateBlogArticleDocument = gql`
    mutation createBlogArticle($input: BlogArticleInput!) {
  createBlogArticle(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateBlogArticleMutationFn = Apollo.MutationFunction<CreateBlogArticleMutation, CreateBlogArticleMutationVariables>;
export function useCreateBlogArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogArticleMutation, CreateBlogArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogArticleMutation, CreateBlogArticleMutationVariables>(CreateBlogArticleDocument, options);
      }
export type CreateBlogArticleMutationHookResult = ReturnType<typeof useCreateBlogArticleMutation>;
export type CreateBlogArticleMutationResult = Apollo.MutationResult<CreateBlogArticleMutation>;
export const CreateBlogSubscriberDocument = gql`
    mutation createBlogSubscriber($data: BlogSubscriberInput!) {
  createBlogSubscriber(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateBlogSubscriberMutationFn = Apollo.MutationFunction<CreateBlogSubscriberMutation, CreateBlogSubscriberMutationVariables>;
export function useCreateBlogSubscriberMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogSubscriberMutation, CreateBlogSubscriberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogSubscriberMutation, CreateBlogSubscriberMutationVariables>(CreateBlogSubscriberDocument, options);
      }
export type CreateBlogSubscriberMutationHookResult = ReturnType<typeof useCreateBlogSubscriberMutation>;
export type CreateBlogSubscriberMutationResult = Apollo.MutationResult<CreateBlogSubscriberMutation>;
export const CreateBlogTagDocument = gql`
    mutation createBlogTag($input: BlogTagInput!) {
  createBlogTag(data: $input) {
    data {
      id
      attributes {
        tagName
        createdAt
        author {
          data {
            id
            attributes {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateBlogTagMutationFn = Apollo.MutationFunction<CreateBlogTagMutation, CreateBlogTagMutationVariables>;
export function useCreateBlogTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogTagMutation, CreateBlogTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogTagMutation, CreateBlogTagMutationVariables>(CreateBlogTagDocument, options);
      }
export type CreateBlogTagMutationHookResult = ReturnType<typeof useCreateBlogTagMutation>;
export type CreateBlogTagMutationResult = Apollo.MutationResult<CreateBlogTagMutation>;
export const CreateChatDocument = gql`
    mutation createChat($data: ChatInput!) {
  createChat(data: $data) {
    data {
      id
      attributes {
        agency {
          data {
            id
          }
        }
        client {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export type CreateChatMutationFn = Apollo.MutationFunction<CreateChatMutation, CreateChatMutationVariables>;
export function useCreateChatMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatMutation, CreateChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatMutation, CreateChatMutationVariables>(CreateChatDocument, options);
      }
export type CreateChatMutationHookResult = ReturnType<typeof useCreateChatMutation>;
export type CreateChatMutationResult = Apollo.MutationResult<CreateChatMutation>;
export const CreateMessageDocument = gql`
    mutation createMessage($data: MessageInput!) {
  createMessage(data: $data) {
    data {
      ...Message
    }
  }
}
    ${MessageFragmentDoc}
${UserFragmentDoc}`;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export const CreateNewMessagesDocument = gql`
    mutation createNewMessages($data: NewMessageInput!) {
  createNewMessage(data: $data) {
    data {
      ...NewMessage
    }
  }
}
    ${NewMessageFragmentDoc}`;
export type CreateNewMessagesMutationFn = Apollo.MutationFunction<CreateNewMessagesMutation, CreateNewMessagesMutationVariables>;
export function useCreateNewMessagesMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewMessagesMutation, CreateNewMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewMessagesMutation, CreateNewMessagesMutationVariables>(CreateNewMessagesDocument, options);
      }
export type CreateNewMessagesMutationHookResult = ReturnType<typeof useCreateNewMessagesMutation>;
export type CreateNewMessagesMutationResult = Apollo.MutationResult<CreateNewMessagesMutation>;
export const CreatePaymentLinkDocument = gql`
    mutation createPaymentLink($input: CreatePaymentLinkInput!) {
  createPaymentLink(input: $input) {
    checkoutUrl
  }
}
    `;
export type CreatePaymentLinkMutationFn = Apollo.MutationFunction<CreatePaymentLinkMutation, CreatePaymentLinkMutationVariables>;
export function useCreatePaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentLinkMutation, CreatePaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentLinkMutation, CreatePaymentLinkMutationVariables>(CreatePaymentLinkDocument, options);
      }
export type CreatePaymentLinkMutationHookResult = ReturnType<typeof useCreatePaymentLinkMutation>;
export type CreatePaymentLinkMutationResult = Apollo.MutationResult<CreatePaymentLinkMutation>;
export const CreatePaymentRefundDocument = gql`
    mutation createPaymentRefund($input: PaymentRefundInput!) {
  paymentRefund(input: $input) {
    status
  }
}
    `;
export type CreatePaymentRefundMutationFn = Apollo.MutationFunction<CreatePaymentRefundMutation, CreatePaymentRefundMutationVariables>;
export function useCreatePaymentRefundMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentRefundMutation, CreatePaymentRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentRefundMutation, CreatePaymentRefundMutationVariables>(CreatePaymentRefundDocument, options);
      }
export type CreatePaymentRefundMutationHookResult = ReturnType<typeof useCreatePaymentRefundMutation>;
export type CreatePaymentRefundMutationResult = Apollo.MutationResult<CreatePaymentRefundMutation>;
export const CreatePropertyDocument = gql`
    mutation createProperty($data: PropertyInput!) {
  createProperty(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreatePropertyMutationFn = Apollo.MutationFunction<CreatePropertyMutation, CreatePropertyMutationVariables>;
export function useCreatePropertyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePropertyMutation, CreatePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePropertyMutation, CreatePropertyMutationVariables>(CreatePropertyDocument, options);
      }
export type CreatePropertyMutationHookResult = ReturnType<typeof useCreatePropertyMutation>;
export type CreatePropertyMutationResult = Apollo.MutationResult<CreatePropertyMutation>;
export const CreatePropertyViewRequestDocument = gql`
    mutation createPropertyViewRequest($data: PropertyViewRequestInput!) {
  createPropertyViewRequest(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreatePropertyViewRequestMutationFn = Apollo.MutationFunction<CreatePropertyViewRequestMutation, CreatePropertyViewRequestMutationVariables>;
export function useCreatePropertyViewRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreatePropertyViewRequestMutation, CreatePropertyViewRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePropertyViewRequestMutation, CreatePropertyViewRequestMutationVariables>(CreatePropertyViewRequestDocument, options);
      }
export type CreatePropertyViewRequestMutationHookResult = ReturnType<typeof useCreatePropertyViewRequestMutation>;
export type CreatePropertyViewRequestMutationResult = Apollo.MutationResult<CreatePropertyViewRequestMutation>;
export const CreateRefundRequestDocument = gql`
    mutation createRefundRequest($input: RefundRequestInput!) {
  createRefundRequest(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateRefundRequestMutationFn = Apollo.MutationFunction<CreateRefundRequestMutation, CreateRefundRequestMutationVariables>;
export function useCreateRefundRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateRefundRequestMutation, CreateRefundRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRefundRequestMutation, CreateRefundRequestMutationVariables>(CreateRefundRequestDocument, options);
      }
export type CreateRefundRequestMutationHookResult = ReturnType<typeof useCreateRefundRequestMutation>;
export type CreateRefundRequestMutationResult = Apollo.MutationResult<CreateRefundRequestMutation>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($input: SubscriptionInput!) {
  createSubscription(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export const CreateSupportFormDocument = gql`
    mutation createSupportForm($input: SupportFormInput!) {
  createSupportForm(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateSupportFormMutationFn = Apollo.MutationFunction<CreateSupportFormMutation, CreateSupportFormMutationVariables>;
export function useCreateSupportFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupportFormMutation, CreateSupportFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupportFormMutation, CreateSupportFormMutationVariables>(CreateSupportFormDocument, options);
      }
export type CreateSupportFormMutationHookResult = ReturnType<typeof useCreateSupportFormMutation>;
export type CreateSupportFormMutationResult = Apollo.MutationResult<CreateSupportFormMutation>;
export const CreateUserFavoriteListDocument = gql`
    mutation createUserFavoriteList($data: UserFavoriteListInput!) {
  createUserFavoriteList(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateUserFavoriteListMutationFn = Apollo.MutationFunction<CreateUserFavoriteListMutation, CreateUserFavoriteListMutationVariables>;
export function useCreateUserFavoriteListMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserFavoriteListMutation, CreateUserFavoriteListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserFavoriteListMutation, CreateUserFavoriteListMutationVariables>(CreateUserFavoriteListDocument, options);
      }
export type CreateUserFavoriteListMutationHookResult = ReturnType<typeof useCreateUserFavoriteListMutation>;
export type CreateUserFavoriteListMutationResult = Apollo.MutationResult<CreateUserFavoriteListMutation>;
export const CreateUserReportDocument = gql`
    mutation createUserReport($input: UserReportInput!) {
  createUserReport(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateUserReportMutationFn = Apollo.MutationFunction<CreateUserReportMutation, CreateUserReportMutationVariables>;
export function useCreateUserReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserReportMutation, CreateUserReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserReportMutation, CreateUserReportMutationVariables>(CreateUserReportDocument, options);
      }
export type CreateUserReportMutationHookResult = ReturnType<typeof useCreateUserReportMutation>;
export type CreateUserReportMutationResult = Apollo.MutationResult<CreateUserReportMutation>;
export const CreateUserViewDocument = gql`
    mutation createUserView($data: UserPropertyViewInput!) {
  createUserPropertyView(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateUserViewMutationFn = Apollo.MutationFunction<CreateUserViewMutation, CreateUserViewMutationVariables>;
export function useCreateUserViewMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserViewMutation, CreateUserViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserViewMutation, CreateUserViewMutationVariables>(CreateUserViewDocument, options);
      }
export type CreateUserViewMutationHookResult = ReturnType<typeof useCreateUserViewMutation>;
export type CreateUserViewMutationResult = Apollo.MutationResult<CreateUserViewMutation>;
export const DeleteBlogArticleDocument = gql`
    mutation deleteBlogArticle($id: ID!) {
  deleteBlogArticle(id: $id) {
    data {
      id
      attributes {
        media {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export type DeleteBlogArticleMutationFn = Apollo.MutationFunction<DeleteBlogArticleMutation, DeleteBlogArticleMutationVariables>;
export function useDeleteBlogArticleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlogArticleMutation, DeleteBlogArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlogArticleMutation, DeleteBlogArticleMutationVariables>(DeleteBlogArticleDocument, options);
      }
export type DeleteBlogArticleMutationHookResult = ReturnType<typeof useDeleteBlogArticleMutation>;
export type DeleteBlogArticleMutationResult = Apollo.MutationResult<DeleteBlogArticleMutation>;
export const DeleteUploadFileDocument = gql`
    mutation deleteUploadFile($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteUploadFileMutationFn = Apollo.MutationFunction<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>;
export function useDeleteUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>(DeleteUploadFileDocument, options);
      }
export type DeleteUploadFileMutationHookResult = ReturnType<typeof useDeleteUploadFileMutation>;
export type DeleteUploadFileMutationResult = Apollo.MutationResult<DeleteUploadFileMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: String!) {
  forgotPassword(email: $input) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      role {
        id
        name
      }
      email
      username
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!, $confirmPassword: String!) {
  resetPassword(
    code: $token
    password: $password
    passwordConfirmation: $confirmPassword
  ) {
    jwt
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const SubscriptionRenewalDocument = gql`
    mutation subscriptionRenewal($input: SubscriptionRenewalInput!) {
  subscriptionRenewal(input: $input) {
    renewal
  }
}
    `;
export type SubscriptionRenewalMutationFn = Apollo.MutationFunction<SubscriptionRenewalMutation, SubscriptionRenewalMutationVariables>;
export function useSubscriptionRenewalMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionRenewalMutation, SubscriptionRenewalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionRenewalMutation, SubscriptionRenewalMutationVariables>(SubscriptionRenewalDocument, options);
      }
export type SubscriptionRenewalMutationHookResult = ReturnType<typeof useSubscriptionRenewalMutation>;
export type SubscriptionRenewalMutationResult = Apollo.MutationResult<SubscriptionRenewalMutation>;
export const UpdateAgencyDocument = gql`
    mutation updateAgency($id: ID!, $input: AgencyInput!) {
  updateAgency(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateAgencyMutationFn = Apollo.MutationFunction<UpdateAgencyMutation, UpdateAgencyMutationVariables>;
export function useUpdateAgencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgencyMutation, UpdateAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgencyMutation, UpdateAgencyMutationVariables>(UpdateAgencyDocument, options);
      }
export type UpdateAgencyMutationHookResult = ReturnType<typeof useUpdateAgencyMutation>;
export type UpdateAgencyMutationResult = Apollo.MutationResult<UpdateAgencyMutation>;
export const UpdateAgencyVerificationRequestDocument = gql`
    mutation updateAgencyVerificationRequest($input: AgencyVerificationRequestInput!, $id: ID!) {
  updateAgencyVerificationRequest(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateAgencyVerificationRequestMutationFn = Apollo.MutationFunction<UpdateAgencyVerificationRequestMutation, UpdateAgencyVerificationRequestMutationVariables>;
export function useUpdateAgencyVerificationRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgencyVerificationRequestMutation, UpdateAgencyVerificationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgencyVerificationRequestMutation, UpdateAgencyVerificationRequestMutationVariables>(UpdateAgencyVerificationRequestDocument, options);
      }
export type UpdateAgencyVerificationRequestMutationHookResult = ReturnType<typeof useUpdateAgencyVerificationRequestMutation>;
export type UpdateAgencyVerificationRequestMutationResult = Apollo.MutationResult<UpdateAgencyVerificationRequestMutation>;
export const UpdateBlogArticleDocument = gql`
    mutation updateBlogArticle($id: ID!, $input: BlogArticleInput!) {
  updateBlogArticle(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateBlogArticleMutationFn = Apollo.MutationFunction<UpdateBlogArticleMutation, UpdateBlogArticleMutationVariables>;
export function useUpdateBlogArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogArticleMutation, UpdateBlogArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlogArticleMutation, UpdateBlogArticleMutationVariables>(UpdateBlogArticleDocument, options);
      }
export type UpdateBlogArticleMutationHookResult = ReturnType<typeof useUpdateBlogArticleMutation>;
export type UpdateBlogArticleMutationResult = Apollo.MutationResult<UpdateBlogArticleMutation>;
export const UpdateChatDocument = gql`
    mutation updateChat($data: ChatInput!, $id: ID!) {
  updateChat(data: $data, id: $id) {
    data {
      ...Chat
    }
  }
}
    ${ChatFragmentDoc}
${FileFragmentDoc}
${MessageFragmentDoc}
${UserFragmentDoc}`;
export type UpdateChatMutationFn = Apollo.MutationFunction<UpdateChatMutation, UpdateChatMutationVariables>;
export function useUpdateChatMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChatMutation, UpdateChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChatMutation, UpdateChatMutationVariables>(UpdateChatDocument, options);
      }
export type UpdateChatMutationHookResult = ReturnType<typeof useUpdateChatMutation>;
export type UpdateChatMutationResult = Apollo.MutationResult<UpdateChatMutation>;
export const UpdateMessageDocument = gql`
    mutation updateMessage($data: MessageInput!, $id: ID!) {
  updateMessage(data: $data, id: $id) {
    data {
      ...Message
    }
  }
}
    ${MessageFragmentDoc}
${UserFragmentDoc}`;
export type UpdateMessageMutationFn = Apollo.MutationFunction<UpdateMessageMutation, UpdateMessageMutationVariables>;
export function useUpdateMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageMutation, UpdateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageMutation, UpdateMessageMutationVariables>(UpdateMessageDocument, options);
      }
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<UpdateMessageMutation>;
export const UpdateNotificationDocument = gql`
    mutation updateNotification($input: NotificationInput!, $id: ID!) {
  updateNotification(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($data: UsersPermissionsUserInput!, $id: ID!) {
  updateUsersPermissionsUser(data: $data, id: $id) {
    data {
      id
      attributes {
        recentSearches
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const UpdatePropertyDocument = gql`
    mutation updateProperty($data: PropertyInput!, $id: ID!) {
  updateProperty(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<UpdatePropertyMutation, UpdatePropertyMutationVariables>;
export function useUpdatePropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropertyMutation, UpdatePropertyMutationVariables>(UpdatePropertyDocument, options);
      }
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>;
export type UpdatePropertyMutationResult = Apollo.MutationResult<UpdatePropertyMutation>;
export const UpdatePropertyViewRequestDocument = gql`
    mutation updatePropertyViewRequest($data: PropertyViewRequestInput!, $id: ID!) {
  updatePropertyViewRequest(data: $data, id: $id) {
    data {
      id
      attributes {
        users_permissions_user {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export type UpdatePropertyViewRequestMutationFn = Apollo.MutationFunction<UpdatePropertyViewRequestMutation, UpdatePropertyViewRequestMutationVariables>;
export function useUpdatePropertyViewRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropertyViewRequestMutation, UpdatePropertyViewRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropertyViewRequestMutation, UpdatePropertyViewRequestMutationVariables>(UpdatePropertyViewRequestDocument, options);
      }
export type UpdatePropertyViewRequestMutationHookResult = ReturnType<typeof useUpdatePropertyViewRequestMutation>;
export type UpdatePropertyViewRequestMutationResult = Apollo.MutationResult<UpdatePropertyViewRequestMutation>;
export const UpdateRefundRequestDocument = gql`
    mutation updateRefundRequest($input: RefundRequestInput!, $id: ID!) {
  updateRefundRequest(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateRefundRequestMutationFn = Apollo.MutationFunction<UpdateRefundRequestMutation, UpdateRefundRequestMutationVariables>;
export function useUpdateRefundRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRefundRequestMutation, UpdateRefundRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRefundRequestMutation, UpdateRefundRequestMutationVariables>(UpdateRefundRequestDocument, options);
      }
export type UpdateRefundRequestMutationHookResult = ReturnType<typeof useUpdateRefundRequestMutation>;
export type UpdateRefundRequestMutationResult = Apollo.MutationResult<UpdateRefundRequestMutation>;
export const UpdateUserSubscriptionPlanDocument = gql`
    mutation updateUserSubscriptionPlan($input: UpdateUserSubscriptionPlanInput!) {
  updateUserSubscriptionPlan(input: $input) {
    customerId
  }
}
    `;
export type UpdateUserSubscriptionPlanMutationFn = Apollo.MutationFunction<UpdateUserSubscriptionPlanMutation, UpdateUserSubscriptionPlanMutationVariables>;
export function useUpdateUserSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSubscriptionPlanMutation, UpdateUserSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSubscriptionPlanMutation, UpdateUserSubscriptionPlanMutationVariables>(UpdateUserSubscriptionPlanDocument, options);
      }
export type UpdateUserSubscriptionPlanMutationHookResult = ReturnType<typeof useUpdateUserSubscriptionPlanMutation>;
export type UpdateUserSubscriptionPlanMutationResult = Apollo.MutationResult<UpdateUserSubscriptionPlanMutation>;
export const UpdateUserFavoriteListDocument = gql`
    mutation updateUserFavoriteList($data: UserFavoriteListInput!, $id: ID!) {
  updateUserFavoriteList(data: $data, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateUserFavoriteListMutationFn = Apollo.MutationFunction<UpdateUserFavoriteListMutation, UpdateUserFavoriteListMutationVariables>;
export function useUpdateUserFavoriteListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserFavoriteListMutation, UpdateUserFavoriteListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserFavoriteListMutation, UpdateUserFavoriteListMutationVariables>(UpdateUserFavoriteListDocument, options);
      }
export type UpdateUserFavoriteListMutationHookResult = ReturnType<typeof useUpdateUserFavoriteListMutation>;
export type UpdateUserFavoriteListMutationResult = Apollo.MutationResult<UpdateUserFavoriteListMutation>;
export const UpdateUseOnlineStatusDocument = gql`
    mutation updateUseOnlineStatus($id: ID!, $isOnline: Boolean!) {
  updateUsersPermissionsUser(data: {isOnline: $isOnline}, id: $id) {
    data {
      id
      attributes {
        isOnline
      }
    }
  }
}
    `;
export type UpdateUseOnlineStatusMutationFn = Apollo.MutationFunction<UpdateUseOnlineStatusMutation, UpdateUseOnlineStatusMutationVariables>;
export function useUpdateUseOnlineStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUseOnlineStatusMutation, UpdateUseOnlineStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUseOnlineStatusMutation, UpdateUseOnlineStatusMutationVariables>(UpdateUseOnlineStatusDocument, options);
      }
export type UpdateUseOnlineStatusMutationHookResult = ReturnType<typeof useUpdateUseOnlineStatusMutation>;
export type UpdateUseOnlineStatusMutationResult = Apollo.MutationResult<UpdateUseOnlineStatusMutation>;
export const UpdateViewChatDocument = gql`
    mutation updateViewChat($data: ChatInput!, $id: ID!) {
  updateChat(data: $data, id: $id) {
    data {
      id
      attributes {
        viewByClient
        viewByAgent
      }
    }
  }
}
    `;
export type UpdateViewChatMutationFn = Apollo.MutationFunction<UpdateViewChatMutation, UpdateViewChatMutationVariables>;
export function useUpdateViewChatMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewChatMutation, UpdateViewChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewChatMutation, UpdateViewChatMutationVariables>(UpdateViewChatDocument, options);
      }
export type UpdateViewChatMutationHookResult = ReturnType<typeof useUpdateViewChatMutation>;
export type UpdateViewChatMutationResult = Apollo.MutationResult<UpdateViewChatMutation>;
export const UploadFileDocument = gql`
    mutation uploadFile($input: UploadFileInput!) {
  createUploadFile(data: $input) {
    data {
      id
    }
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export const AgenciesDocument = gql`
    query agencies($limit: Int, $pageSize: Int, $page: Int, $filters: AgencyFiltersInput) {
  agencies(
    filters: $filters
    pagination: {limit: $limit, pageSize: $pageSize, page: $page}
  ) {
    data {
      id
      attributes {
        ...Agency
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${AgencyFragmentDoc}
${PropertyFragmentDoc}
${SetFragmentDoc}
${AgencyReviewFragmentDoc}
${FileFragmentDoc}`;
export function useAgenciesQuery(baseOptions?: Apollo.QueryHookOptions<AgenciesQuery, AgenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgenciesQuery, AgenciesQueryVariables>(AgenciesDocument, options);
      }
export function useAgenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgenciesQuery, AgenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgenciesQuery, AgenciesQueryVariables>(AgenciesDocument, options);
        }
export type AgenciesQueryHookResult = ReturnType<typeof useAgenciesQuery>;
export type AgenciesLazyQueryHookResult = ReturnType<typeof useAgenciesLazyQuery>;
export type AgenciesQueryResult = Apollo.QueryResult<AgenciesQuery, AgenciesQueryVariables>;
export const AgencyBySlugDocument = gql`
    query agencyBySlug($slug: String!) {
  agencies(filters: {slug: {eq: $slug}}) {
    data {
      id
      attributes {
        agencyName
        aboutUs
        agencyAddress
        websiteLink
        workingHoursFrom
        workingHoursTo
        phoneNumber
        reviewsCount
        createdAt
        averageRating
        verificationStatus
        agency_reviews(pagination: {limit: -1}) {
          data {
            id
            attributes {
              ...AgencyReview
            }
          }
        }
        agencyLogo {
          data {
            ...File
          }
        }
        slug
        agent {
          data {
            id
            attributes {
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }
      }
    }
  }
}
    ${AgencyReviewFragmentDoc}
${FileFragmentDoc}`;
export function useAgencyBySlugQuery(baseOptions: Apollo.QueryHookOptions<AgencyBySlugQuery, AgencyBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgencyBySlugQuery, AgencyBySlugQueryVariables>(AgencyBySlugDocument, options);
      }
export function useAgencyBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyBySlugQuery, AgencyBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgencyBySlugQuery, AgencyBySlugQueryVariables>(AgencyBySlugDocument, options);
        }
export type AgencyBySlugQueryHookResult = ReturnType<typeof useAgencyBySlugQuery>;
export type AgencyBySlugLazyQueryHookResult = ReturnType<typeof useAgencyBySlugLazyQuery>;
export type AgencyBySlugQueryResult = Apollo.QueryResult<AgencyBySlugQuery, AgencyBySlugQueryVariables>;
export const AgencyReviewsDocument = gql`
    query agencyReviews {
  agencyReviews(pagination: {limit: -1}) {
    data {
      id
      attributes {
        ...AgencyReview
      }
    }
  }
}
    ${AgencyReviewFragmentDoc}
${FileFragmentDoc}`;
export function useAgencyReviewsQuery(baseOptions?: Apollo.QueryHookOptions<AgencyReviewsQuery, AgencyReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgencyReviewsQuery, AgencyReviewsQueryVariables>(AgencyReviewsDocument, options);
      }
export function useAgencyReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyReviewsQuery, AgencyReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgencyReviewsQuery, AgencyReviewsQueryVariables>(AgencyReviewsDocument, options);
        }
export type AgencyReviewsQueryHookResult = ReturnType<typeof useAgencyReviewsQuery>;
export type AgencyReviewsLazyQueryHookResult = ReturnType<typeof useAgencyReviewsLazyQuery>;
export type AgencyReviewsQueryResult = Apollo.QueryResult<AgencyReviewsQuery, AgencyReviewsQueryVariables>;
export const AgencyVerificationRequestsDocument = gql`
    query agencyVerificationRequests($filter: AgencyVerificationRequestFiltersInput) {
  agencyVerificationRequests(filters: $filter, sort: ["desc"]) {
    data {
      id
      attributes {
        status
        createdAt
        agency {
          data {
            id
            attributes {
              ...Agency
            }
          }
        }
      }
    }
  }
}
    ${AgencyFragmentDoc}
${PropertyFragmentDoc}
${SetFragmentDoc}
${AgencyReviewFragmentDoc}
${FileFragmentDoc}`;
export function useAgencyVerificationRequestsQuery(baseOptions?: Apollo.QueryHookOptions<AgencyVerificationRequestsQuery, AgencyVerificationRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgencyVerificationRequestsQuery, AgencyVerificationRequestsQueryVariables>(AgencyVerificationRequestsDocument, options);
      }
export function useAgencyVerificationRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyVerificationRequestsQuery, AgencyVerificationRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgencyVerificationRequestsQuery, AgencyVerificationRequestsQueryVariables>(AgencyVerificationRequestsDocument, options);
        }
export type AgencyVerificationRequestsQueryHookResult = ReturnType<typeof useAgencyVerificationRequestsQuery>;
export type AgencyVerificationRequestsLazyQueryHookResult = ReturnType<typeof useAgencyVerificationRequestsLazyQuery>;
export type AgencyVerificationRequestsQueryResult = Apollo.QueryResult<AgencyVerificationRequestsQuery, AgencyVerificationRequestsQueryVariables>;
export const BlogArticleBySlugDocument = gql`
    query blogArticleBySlug($slug: String!) {
  blogArticles(filters: {slug: {eq: $slug}}) {
    data {
      id
      attributes {
        ...BlogArticle
      }
    }
  }
}
    ${BlogArticleFragmentDoc}
${FileFragmentDoc}`;
export function useBlogArticleBySlugQuery(baseOptions: Apollo.QueryHookOptions<BlogArticleBySlugQuery, BlogArticleBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogArticleBySlugQuery, BlogArticleBySlugQueryVariables>(BlogArticleBySlugDocument, options);
      }
export function useBlogArticleBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogArticleBySlugQuery, BlogArticleBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogArticleBySlugQuery, BlogArticleBySlugQueryVariables>(BlogArticleBySlugDocument, options);
        }
export type BlogArticleBySlugQueryHookResult = ReturnType<typeof useBlogArticleBySlugQuery>;
export type BlogArticleBySlugLazyQueryHookResult = ReturnType<typeof useBlogArticleBySlugLazyQuery>;
export type BlogArticleBySlugQueryResult = Apollo.QueryResult<BlogArticleBySlugQuery, BlogArticleBySlugQueryVariables>;
export const BlogArticlesDocument = gql`
    query blogArticles($limit: Int, $filters: BlogArticleFiltersInput) {
  blogArticles(pagination: {limit: $limit}, filters: $filters) {
    data {
      id
      attributes {
        ...BlogArticle
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${BlogArticleFragmentDoc}
${FileFragmentDoc}`;
export function useBlogArticlesQuery(baseOptions?: Apollo.QueryHookOptions<BlogArticlesQuery, BlogArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogArticlesQuery, BlogArticlesQueryVariables>(BlogArticlesDocument, options);
      }
export function useBlogArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogArticlesQuery, BlogArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogArticlesQuery, BlogArticlesQueryVariables>(BlogArticlesDocument, options);
        }
export type BlogArticlesQueryHookResult = ReturnType<typeof useBlogArticlesQuery>;
export type BlogArticlesLazyQueryHookResult = ReturnType<typeof useBlogArticlesLazyQuery>;
export type BlogArticlesQueryResult = Apollo.QueryResult<BlogArticlesQuery, BlogArticlesQueryVariables>;
export const BlogTagsDocument = gql`
    query blogTags($filters: BlogTagFiltersInput) {
  blogTags(filters: $filters) {
    data {
      id
      attributes {
        tagName
        createdAt
        author {
          data {
            id
            attributes {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;
export function useBlogTagsQuery(baseOptions?: Apollo.QueryHookOptions<BlogTagsQuery, BlogTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogTagsQuery, BlogTagsQueryVariables>(BlogTagsDocument, options);
      }
export function useBlogTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogTagsQuery, BlogTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogTagsQuery, BlogTagsQueryVariables>(BlogTagsDocument, options);
        }
export type BlogTagsQueryHookResult = ReturnType<typeof useBlogTagsQuery>;
export type BlogTagsLazyQueryHookResult = ReturnType<typeof useBlogTagsLazyQuery>;
export type BlogTagsQueryResult = Apollo.QueryResult<BlogTagsQuery, BlogTagsQueryVariables>;
export const ChatDocument = gql`
    query chat($id: ID!) {
  chat(id: $id) {
    data {
      ...Chat
    }
  }
}
    ${ChatFragmentDoc}
${FileFragmentDoc}
${MessageFragmentDoc}
${UserFragmentDoc}`;
export function useChatQuery(baseOptions: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
      }
export function useChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
        }
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const ChatsDocument = gql`
    query chats($clientId: ID, $agentId: ID, $userId: ID) {
  chats(
    filters: {user: {id: {eq: $userId}}, client: {id: {eq: $clientId}}, agency: {id: {eq: $agentId}}}
    pagination: {limit: -1}
  ) {
    data {
      ...Chat
    }
  }
}
    ${ChatFragmentDoc}
${FileFragmentDoc}
${MessageFragmentDoc}
${UserFragmentDoc}`;
export function useChatsQuery(baseOptions?: Apollo.QueryHookOptions<ChatsQuery, ChatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatsQuery, ChatsQueryVariables>(ChatsDocument, options);
      }
export function useChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatsQuery, ChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatsQuery, ChatsQueryVariables>(ChatsDocument, options);
        }
export type ChatsQueryHookResult = ReturnType<typeof useChatsQuery>;
export type ChatsLazyQueryHookResult = ReturnType<typeof useChatsLazyQuery>;
export type ChatsQueryResult = Apollo.QueryResult<ChatsQuery, ChatsQueryVariables>;
export const CitiesWithPostcodesDocument = gql`
    query citiesWithPostcodes {
  cities(pagination: {limit: -1}) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useCitiesWithPostcodesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesWithPostcodesQuery, CitiesWithPostcodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesWithPostcodesQuery, CitiesWithPostcodesQueryVariables>(CitiesWithPostcodesDocument, options);
      }
export function useCitiesWithPostcodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesWithPostcodesQuery, CitiesWithPostcodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesWithPostcodesQuery, CitiesWithPostcodesQueryVariables>(CitiesWithPostcodesDocument, options);
        }
export type CitiesWithPostcodesQueryHookResult = ReturnType<typeof useCitiesWithPostcodesQuery>;
export type CitiesWithPostcodesLazyQueryHookResult = ReturnType<typeof useCitiesWithPostcodesLazyQuery>;
export type CitiesWithPostcodesQueryResult = Apollo.QueryResult<CitiesWithPostcodesQuery, CitiesWithPostcodesQueryVariables>;
export const DocumentsDocument = gql`
    query documents {
  documents {
    data {
      id
      attributes {
        name
        file {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
      }
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const EmptyStateDocument = gql`
    query emptyState($filters: EmptyStateFiltersInput) {
  emptyStates(filters: $filters) {
    data {
      ...EmptyState
    }
  }
}
    ${EmptyStateFragmentDoc}
${FileFragmentDoc}`;
export function useEmptyStateQuery(baseOptions?: Apollo.QueryHookOptions<EmptyStateQuery, EmptyStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmptyStateQuery, EmptyStateQueryVariables>(EmptyStateDocument, options);
      }
export function useEmptyStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmptyStateQuery, EmptyStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmptyStateQuery, EmptyStateQueryVariables>(EmptyStateDocument, options);
        }
export type EmptyStateQueryHookResult = ReturnType<typeof useEmptyStateQuery>;
export type EmptyStateLazyQueryHookResult = ReturnType<typeof useEmptyStateLazyQuery>;
export type EmptyStateQueryResult = Apollo.QueryResult<EmptyStateQuery, EmptyStateQueryVariables>;
export const ForgotPasswordPageDocument = gql`
    query forgotPasswordPage {
  forgotPassword {
    data {
      id
      attributes {
        heading {
          ...Headline
        }
        image {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${HeadlineFragmentDoc}
${FileFragmentDoc}`;
export function useForgotPasswordPageQuery(baseOptions?: Apollo.QueryHookOptions<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>(ForgotPasswordPageDocument, options);
      }
export function useForgotPasswordPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>(ForgotPasswordPageDocument, options);
        }
export type ForgotPasswordPageQueryHookResult = ReturnType<typeof useForgotPasswordPageQuery>;
export type ForgotPasswordPageLazyQueryHookResult = ReturnType<typeof useForgotPasswordPageLazyQuery>;
export type ForgotPasswordPageQueryResult = Apollo.QueryResult<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>;
export const GetAllChatsDocument = gql`
    query getAllChats($filters: ChatFiltersInput) {
  chats(filters: $filters, pagination: {limit: -1}) {
    data {
      ...Chat
    }
  }
}
    ${ChatFragmentDoc}
${FileFragmentDoc}
${MessageFragmentDoc}
${UserFragmentDoc}`;
export function useGetAllChatsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllChatsQuery, GetAllChatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllChatsQuery, GetAllChatsQueryVariables>(GetAllChatsDocument, options);
      }
export function useGetAllChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChatsQuery, GetAllChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllChatsQuery, GetAllChatsQueryVariables>(GetAllChatsDocument, options);
        }
export type GetAllChatsQueryHookResult = ReturnType<typeof useGetAllChatsQuery>;
export type GetAllChatsLazyQueryHookResult = ReturnType<typeof useGetAllChatsLazyQuery>;
export type GetAllChatsQueryResult = Apollo.QueryResult<GetAllChatsQuery, GetAllChatsQueryVariables>;
export const GetAllChatsForAgentDocument = gql`
    query getAllChatsForAgent($filters: ChatFiltersInput) {
  chats(filters: $filters, pagination: {limit: -1}) {
    data {
      ...Chat
    }
  }
}
    ${ChatFragmentDoc}
${FileFragmentDoc}
${MessageFragmentDoc}
${UserFragmentDoc}`;
export function useGetAllChatsForAgentQuery(baseOptions?: Apollo.QueryHookOptions<GetAllChatsForAgentQuery, GetAllChatsForAgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllChatsForAgentQuery, GetAllChatsForAgentQueryVariables>(GetAllChatsForAgentDocument, options);
      }
export function useGetAllChatsForAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChatsForAgentQuery, GetAllChatsForAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllChatsForAgentQuery, GetAllChatsForAgentQueryVariables>(GetAllChatsForAgentDocument, options);
        }
export type GetAllChatsForAgentQueryHookResult = ReturnType<typeof useGetAllChatsForAgentQuery>;
export type GetAllChatsForAgentLazyQueryHookResult = ReturnType<typeof useGetAllChatsForAgentLazyQuery>;
export type GetAllChatsForAgentQueryResult = Apollo.QueryResult<GetAllChatsForAgentQuery, GetAllChatsForAgentQueryVariables>;
export const GetAllMessagesDocument = gql`
    query getAllMessages($filters: MessageFiltersInput!) {
  messages(filters: $filters, pagination: {limit: -1}) {
    data {
      ...Message
    }
  }
}
    ${MessageFragmentDoc}
${UserFragmentDoc}`;
export function useGetAllMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetAllMessagesQuery, GetAllMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMessagesQuery, GetAllMessagesQueryVariables>(GetAllMessagesDocument, options);
      }
export function useGetAllMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMessagesQuery, GetAllMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMessagesQuery, GetAllMessagesQueryVariables>(GetAllMessagesDocument, options);
        }
export type GetAllMessagesQueryHookResult = ReturnType<typeof useGetAllMessagesQuery>;
export type GetAllMessagesLazyQueryHookResult = ReturnType<typeof useGetAllMessagesLazyQuery>;
export type GetAllMessagesQueryResult = Apollo.QueryResult<GetAllMessagesQuery, GetAllMessagesQueryVariables>;
export const PlatformReviewsDocument = gql`
    query platformReviews($limit: Int) {
  platformReviews(pagination: {limit: $limit}) {
    data {
      id
      attributes {
        ...PlatformReview
      }
    }
  }
}
    ${PlatformReviewFragmentDoc}
${FileFragmentDoc}`;
export function usePlatformReviewsQuery(baseOptions?: Apollo.QueryHookOptions<PlatformReviewsQuery, PlatformReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformReviewsQuery, PlatformReviewsQueryVariables>(PlatformReviewsDocument, options);
      }
export function usePlatformReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformReviewsQuery, PlatformReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformReviewsQuery, PlatformReviewsQueryVariables>(PlatformReviewsDocument, options);
        }
export type PlatformReviewsQueryHookResult = ReturnType<typeof usePlatformReviewsQuery>;
export type PlatformReviewsLazyQueryHookResult = ReturnType<typeof usePlatformReviewsLazyQuery>;
export type PlatformReviewsQueryResult = Apollo.QueryResult<PlatformReviewsQuery, PlatformReviewsQueryVariables>;
export const GetPropertiesAddressesDocument = gql`
    query getPropertiesAddresses {
  properties(pagination: {limit: -1}) {
    data {
      id
      attributes {
        address1
        address2
      }
    }
  }
}
    `;
export function useGetPropertiesAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetPropertiesAddressesQuery, GetPropertiesAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertiesAddressesQuery, GetPropertiesAddressesQueryVariables>(GetPropertiesAddressesDocument, options);
      }
export function useGetPropertiesAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesAddressesQuery, GetPropertiesAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertiesAddressesQuery, GetPropertiesAddressesQueryVariables>(GetPropertiesAddressesDocument, options);
        }
export type GetPropertiesAddressesQueryHookResult = ReturnType<typeof useGetPropertiesAddressesQuery>;
export type GetPropertiesAddressesLazyQueryHookResult = ReturnType<typeof useGetPropertiesAddressesLazyQuery>;
export type GetPropertiesAddressesQueryResult = Apollo.QueryResult<GetPropertiesAddressesQuery, GetPropertiesAddressesQueryVariables>;
export const GetPropertyBuIdDocument = gql`
    query getPropertyBuId($id: ID!) {
  property(id: $id) {
    data {
      id
      attributes {
        ...Property
      }
    }
  }
}
    ${PropertyFragmentDoc}
${SetFragmentDoc}`;
export function useGetPropertyBuIdQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyBuIdQuery, GetPropertyBuIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyBuIdQuery, GetPropertyBuIdQueryVariables>(GetPropertyBuIdDocument, options);
      }
export function useGetPropertyBuIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyBuIdQuery, GetPropertyBuIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyBuIdQuery, GetPropertyBuIdQueryVariables>(GetPropertyBuIdDocument, options);
        }
export type GetPropertyBuIdQueryHookResult = ReturnType<typeof useGetPropertyBuIdQuery>;
export type GetPropertyBuIdLazyQueryHookResult = ReturnType<typeof useGetPropertyBuIdLazyQuery>;
export type GetPropertyBuIdQueryResult = Apollo.QueryResult<GetPropertyBuIdQuery, GetPropertyBuIdQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        WhyWeExcel {
          ...Section
        }
        findNewProperty {
          ...Section
        }
        gallery {
          ...Section
        }
        ourRecomendation {
          ...Section
        }
        sharedStrories {
          ...Section
        }
        ourBlog {
          ...Section
        }
        signUp {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        headerMenu {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    role {
      data {
        id
        attributes {
          name
        }
      }
    }
    recentSearches
    firstName
    lastName
    email
    addressLine1
    addressLine2
    isAbleToSwitchRole
    budget
    situation
    phoneNumber
    postalCode
    city
    country
    stripeCustomerId
    preferredBathroomsNumber
    preferredBedroomsNumber
    preferredLocation
    propertyType
    stateOrProvince
    currentRegistrationStep
    agency {
      data {
        id
        attributes {
          ...Agency
        }
      }
    }
    avatar {
      data {
        ...File
      }
    }
  }
}
    ${AgencyFragmentDoc}
${PropertyFragmentDoc}
${SetFragmentDoc}
${AgencyReviewFragmentDoc}
${FileFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MessagesDocument = gql`
    query messages($senderId: ID!, $reciverId: ID, $chatId: ID) {
  messages(
    filters: {chat: {id: {eq: $chatId}}, sender: {id: {eq: $senderId}}, receiver: {id: {eq: $reciverId}}}
    pagination: {limit: -1}
  ) {
    data {
      ...Message
    }
  }
}
    ${MessageFragmentDoc}
${UserFragmentDoc}`;
export function useMessagesQuery(baseOptions: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const NearbyPropertiesDocument = gql`
    query nearbyProperties($input: NearbyPropertiesInput) {
  nearbyProperties(input: $input) {
    properties {
      data {
        id
        attributes {
          price
          address1
          address2
          square
          bathrooms
          baths
          rentPrice
          media {
            data {
              ...File
            }
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useNearbyPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<NearbyPropertiesQuery, NearbyPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NearbyPropertiesQuery, NearbyPropertiesQueryVariables>(NearbyPropertiesDocument, options);
      }
export function useNearbyPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NearbyPropertiesQuery, NearbyPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NearbyPropertiesQuery, NearbyPropertiesQueryVariables>(NearbyPropertiesDocument, options);
        }
export type NearbyPropertiesQueryHookResult = ReturnType<typeof useNearbyPropertiesQuery>;
export type NearbyPropertiesLazyQueryHookResult = ReturnType<typeof useNearbyPropertiesLazyQuery>;
export type NearbyPropertiesQueryResult = Apollo.QueryResult<NearbyPropertiesQuery, NearbyPropertiesQueryVariables>;
export const NewMessagesDocument = gql`
    query newMessages($filters: NewMessageFiltersInput!) {
  newMessages(filters: $filters) {
    data {
      id
      attributes {
        from {
          data {
            id
          }
        }
        to {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export function useNewMessagesQuery(baseOptions: Apollo.QueryHookOptions<NewMessagesQuery, NewMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewMessagesQuery, NewMessagesQueryVariables>(NewMessagesDocument, options);
      }
export function useNewMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewMessagesQuery, NewMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewMessagesQuery, NewMessagesQueryVariables>(NewMessagesDocument, options);
        }
export type NewMessagesQueryHookResult = ReturnType<typeof useNewMessagesQuery>;
export type NewMessagesLazyQueryHookResult = ReturnType<typeof useNewMessagesLazyQuery>;
export type NewMessagesQueryResult = Apollo.QueryResult<NewMessagesQuery, NewMessagesQueryVariables>;
export const NotificationsDocument = gql`
    query notifications($filters: NotificationFiltersInput) {
  notifications(
    filters: $filters
    pagination: {limit: 6}
    sort: ["createdAt:desc"]
  ) {
    data {
      id
      attributes {
        users {
          data {
            id
          }
        }
        type
        isNew
        createdAt
        users {
          data {
            id
            attributes {
              firstName
              lastName
            }
          }
        }
        agency_verification_request {
          data {
            id
            attributes {
              status
              createdAt
              agency {
                data {
                  id
                  attributes {
                    agencyName
                  }
                }
              }
            }
          }
        }
        property_view_request {
          data {
            id
            attributes {
              createdAt
              requestStatus
              property {
                data {
                  id
                  attributes {
                    address1
                    address2
                  }
                }
              }
              users_permissions_user {
                data {
                  id
                  attributes {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const PlacesNearbyDocument = gql`
    query placesNearby($input: PlacesNearbyInput!) {
  placesNearby(input: $input) {
    results {
      place_id
      distance
      name
      type
    }
  }
}
    `;
export function usePlacesNearbyQuery(baseOptions: Apollo.QueryHookOptions<PlacesNearbyQuery, PlacesNearbyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlacesNearbyQuery, PlacesNearbyQueryVariables>(PlacesNearbyDocument, options);
      }
export function usePlacesNearbyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlacesNearbyQuery, PlacesNearbyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlacesNearbyQuery, PlacesNearbyQueryVariables>(PlacesNearbyDocument, options);
        }
export type PlacesNearbyQueryHookResult = ReturnType<typeof usePlacesNearbyQuery>;
export type PlacesNearbyLazyQueryHookResult = ReturnType<typeof usePlacesNearbyLazyQuery>;
export type PlacesNearbyQueryResult = Apollo.QueryResult<PlacesNearbyQuery, PlacesNearbyQueryVariables>;
export const PortfolioDocument = gql`
    query portfolio {
  portfolios {
    data {
      id
      attributes {
        image {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function usePortfolioQuery(baseOptions?: Apollo.QueryHookOptions<PortfolioQuery, PortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortfolioQuery, PortfolioQueryVariables>(PortfolioDocument, options);
      }
export function usePortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfolioQuery, PortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortfolioQuery, PortfolioQueryVariables>(PortfolioDocument, options);
        }
export type PortfolioQueryHookResult = ReturnType<typeof usePortfolioQuery>;
export type PortfolioLazyQueryHookResult = ReturnType<typeof usePortfolioLazyQuery>;
export type PortfolioQueryResult = Apollo.QueryResult<PortfolioQuery, PortfolioQueryVariables>;
export const PropertiesDocument = gql`
    query properties($limit: Int, $filters: PropertyFiltersInput, $sort: [String] = [], $page: Int, $pageSize: Int) {
  properties(
    pagination: {limit: $limit, page: $page, pageSize: $pageSize}
    filters: $filters
    sort: $sort
  ) {
    data {
      id
      attributes {
        ...Property
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${PropertyFragmentDoc}
${SetFragmentDoc}`;
export function usePropertiesQuery(baseOptions?: Apollo.QueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
      }
export function usePropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
        }
export type PropertiesQueryHookResult = ReturnType<typeof usePropertiesQuery>;
export type PropertiesLazyQueryHookResult = ReturnType<typeof usePropertiesLazyQuery>;
export type PropertiesQueryResult = Apollo.QueryResult<PropertiesQuery, PropertiesQueryVariables>;
export const PropertyViewRequestDocument = gql`
    query propertyViewRequest($filter: PropertyViewRequestFiltersInput) {
  propertyViewRequests(filters: $filter, pagination: {limit: -1}) {
    data {
      id
      attributes {
        requestStatus
        comment
        updatedAt
        isExpired
        createdAt
        viewingDate
        users_permissions_user {
          data {
            id
            attributes {
              avatar {
                data {
                  ...File
                }
              }
              firstName
              lastName
              phoneNumber
              email
              confirmed
              budget
            }
          }
        }
        property {
          data {
            id
            attributes {
              agency {
                data {
                  id
                }
              }
              ...Property
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${FileFragmentDoc}
${PropertyFragmentDoc}
${SetFragmentDoc}`;
export function usePropertyViewRequestQuery(baseOptions?: Apollo.QueryHookOptions<PropertyViewRequestQuery, PropertyViewRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertyViewRequestQuery, PropertyViewRequestQueryVariables>(PropertyViewRequestDocument, options);
      }
export function usePropertyViewRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertyViewRequestQuery, PropertyViewRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertyViewRequestQuery, PropertyViewRequestQueryVariables>(PropertyViewRequestDocument, options);
        }
export type PropertyViewRequestQueryHookResult = ReturnType<typeof usePropertyViewRequestQuery>;
export type PropertyViewRequestLazyQueryHookResult = ReturnType<typeof usePropertyViewRequestLazyQuery>;
export type PropertyViewRequestQueryResult = Apollo.QueryResult<PropertyViewRequestQuery, PropertyViewRequestQueryVariables>;
export const RefundRequestsDocument = gql`
    query refundRequests {
  refundRequests(filters: {status: {eq: "requested"}}) {
    data {
      id
      attributes {
        reason
        status
        createdAt
        subscription {
          data {
            id
            attributes {
              ...Subscription
            }
          }
        }
        users_permissions_user {
          data {
            id
            attributes {
              email
              stripeCustomerId
            }
          }
        }
      }
    }
  }
}
    ${SubscriptionFragmentDoc}`;
export function useRefundRequestsQuery(baseOptions?: Apollo.QueryHookOptions<RefundRequestsQuery, RefundRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefundRequestsQuery, RefundRequestsQueryVariables>(RefundRequestsDocument, options);
      }
export function useRefundRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefundRequestsQuery, RefundRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefundRequestsQuery, RefundRequestsQueryVariables>(RefundRequestsDocument, options);
        }
export type RefundRequestsQueryHookResult = ReturnType<typeof useRefundRequestsQuery>;
export type RefundRequestsLazyQueryHookResult = ReturnType<typeof useRefundRequestsLazyQuery>;
export type RefundRequestsQueryResult = Apollo.QueryResult<RefundRequestsQuery, RefundRequestsQueryVariables>;
export const ResetPasswordPageDocument = gql`
    query resetPasswordPage {
  resetPassword {
    data {
      id
      attributes {
        heading {
          ...Headline
        }
        image {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${HeadlineFragmentDoc}
${FileFragmentDoc}`;
export function useResetPasswordPageQuery(baseOptions?: Apollo.QueryHookOptions<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>(ResetPasswordPageDocument, options);
      }
export function useResetPasswordPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>(ResetPasswordPageDocument, options);
        }
export type ResetPasswordPageQueryHookResult = ReturnType<typeof useResetPasswordPageQuery>;
export type ResetPasswordPageLazyQueryHookResult = ReturnType<typeof useResetPasswordPageLazyQuery>;
export type ResetPasswordPageQueryResult = Apollo.QueryResult<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>;
export const RolesDocument = gql`
    query roles {
  usersPermissionsRoles {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const SignIngDocument = gql`
    query signIng {
  signIn {
    data {
      attributes {
        media {
          data {
            ...File
          }
        }
        heading {
          ...Headline
        }
      }
    }
  }
}
    ${FileFragmentDoc}
${HeadlineFragmentDoc}`;
export function useSignIngQuery(baseOptions?: Apollo.QueryHookOptions<SignIngQuery, SignIngQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignIngQuery, SignIngQueryVariables>(SignIngDocument, options);
      }
export function useSignIngLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignIngQuery, SignIngQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignIngQuery, SignIngQueryVariables>(SignIngDocument, options);
        }
export type SignIngQueryHookResult = ReturnType<typeof useSignIngQuery>;
export type SignIngLazyQueryHookResult = ReturnType<typeof useSignIngLazyQuery>;
export type SignIngQueryResult = Apollo.QueryResult<SignIngQuery, SignIngQueryVariables>;
export const SignupStepDocument = gql`
    query SignupStep($stepNumber: Int!) {
  signupSteps(filters: {stepNumber: {eq: $stepNumber}}) {
    data {
      ...SignupStep
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${SignupStepFragmentDoc}
${FileFragmentDoc}`;
export function useSignupStepQuery(baseOptions: Apollo.QueryHookOptions<SignupStepQuery, SignupStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignupStepQuery, SignupStepQueryVariables>(SignupStepDocument, options);
      }
export function useSignupStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignupStepQuery, SignupStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignupStepQuery, SignupStepQueryVariables>(SignupStepDocument, options);
        }
export type SignupStepQueryHookResult = ReturnType<typeof useSignupStepQuery>;
export type SignupStepLazyQueryHookResult = ReturnType<typeof useSignupStepLazyQuery>;
export type SignupStepQueryResult = Apollo.QueryResult<SignupStepQuery, SignupStepQueryVariables>;
export const SubscriptionPlanDocument = gql`
    query subscriptionPlan {
  subscriptionPlan {
    data {
      id
      attributes {
        plan {
          id
          description
          price
          period
          discount
          type
          currencySign
          stripeProductId
        }
      }
    }
  }
}
    `;
export function useSubscriptionPlanQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
      }
export function useSubscriptionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
        }
export type SubscriptionPlanQueryHookResult = ReturnType<typeof useSubscriptionPlanQuery>;
export type SubscriptionPlanLazyQueryHookResult = ReturnType<typeof useSubscriptionPlanLazyQuery>;
export type SubscriptionPlanQueryResult = Apollo.QueryResult<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>;
export const SubscriptionsDocument = gql`
    query subscriptions($filters: SubscriptionFiltersInput) {
  subscriptions(
    filters: $filters
    pagination: {limit: -1}
    sort: ["createdAt:desc"]
  ) {
    data {
      id
      attributes {
        ...Subscription
      }
    }
  }
}
    ${SubscriptionFragmentDoc}`;
export function useSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsQueryResult = Apollo.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const UserFavoriteListsDocument = gql`
    query userFavoriteLists($filters: UserFavoriteListFiltersInput!) {
  userFavoriteLists(filters: $filters) {
    data {
      id
      attributes {
        listName
        properties {
          data {
            id
            attributes {
              media {
                data {
                  ...File
                }
              }
              square
              address1
              address2
              bedrooms
              price
              rentPrice
              propertyStatus
            }
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useUserFavoriteListsQuery(baseOptions: Apollo.QueryHookOptions<UserFavoriteListsQuery, UserFavoriteListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFavoriteListsQuery, UserFavoriteListsQueryVariables>(UserFavoriteListsDocument, options);
      }
export function useUserFavoriteListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFavoriteListsQuery, UserFavoriteListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFavoriteListsQuery, UserFavoriteListsQueryVariables>(UserFavoriteListsDocument, options);
        }
export type UserFavoriteListsQueryHookResult = ReturnType<typeof useUserFavoriteListsQuery>;
export type UserFavoriteListsLazyQueryHookResult = ReturnType<typeof useUserFavoriteListsLazyQuery>;
export type UserFavoriteListsQueryResult = Apollo.QueryResult<UserFavoriteListsQuery, UserFavoriteListsQueryVariables>;
export const UserPropertyViewsDocument = gql`
    query userPropertyViews($filters: UserPropertyViewFiltersInput, $limit: Int!) {
  userPropertyViews(filters: $filters, pagination: {limit: $limit}) {
    data {
      id
      attributes {
        createdAt
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useUserPropertyViewsQuery(baseOptions: Apollo.QueryHookOptions<UserPropertyViewsQuery, UserPropertyViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPropertyViewsQuery, UserPropertyViewsQueryVariables>(UserPropertyViewsDocument, options);
      }
export function useUserPropertyViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPropertyViewsQuery, UserPropertyViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPropertyViewsQuery, UserPropertyViewsQueryVariables>(UserPropertyViewsDocument, options);
        }
export type UserPropertyViewsQueryHookResult = ReturnType<typeof useUserPropertyViewsQuery>;
export type UserPropertyViewsLazyQueryHookResult = ReturnType<typeof useUserPropertyViewsLazyQuery>;
export type UserPropertyViewsQueryResult = Apollo.QueryResult<UserPropertyViewsQuery, UserPropertyViewsQueryVariables>;
export const UserPropertyViewsExtendedDocument = gql`
    query userPropertyViewsExtended($filters: UserPropertyViewFiltersInput, $limit: Int!) {
  userPropertyViews(filters: $filters, pagination: {limit: $limit}) {
    data {
      id
      attributes {
        createdAt
        property {
          data {
            id
            attributes {
              ...Property
            }
          }
        }
        users_permissions_user {
          data {
            id
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${PropertyFragmentDoc}
${SetFragmentDoc}`;
export function useUserPropertyViewsExtendedQuery(baseOptions: Apollo.QueryHookOptions<UserPropertyViewsExtendedQuery, UserPropertyViewsExtendedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPropertyViewsExtendedQuery, UserPropertyViewsExtendedQueryVariables>(UserPropertyViewsExtendedDocument, options);
      }
export function useUserPropertyViewsExtendedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPropertyViewsExtendedQuery, UserPropertyViewsExtendedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPropertyViewsExtendedQuery, UserPropertyViewsExtendedQueryVariables>(UserPropertyViewsExtendedDocument, options);
        }
export type UserPropertyViewsExtendedQueryHookResult = ReturnType<typeof useUserPropertyViewsExtendedQuery>;
export type UserPropertyViewsExtendedLazyQueryHookResult = ReturnType<typeof useUserPropertyViewsExtendedLazyQuery>;
export type UserPropertyViewsExtendedQueryResult = Apollo.QueryResult<UserPropertyViewsExtendedQuery, UserPropertyViewsExtendedQueryVariables>;
export const ReportsDocument = gql`
    query reports {
  userReports {
    data {
      id
      attributes {
        ...UserReport
      }
    }
  }
}
    ${UserReportFragmentDoc}
${FileFragmentDoc}`;
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const UsersDocument = gql`
    query users($page: Int, $sort: [String] = [], $filters: UsersPermissionsUserFiltersInput, $pageSize: Int, $limit: Int) {
  usersPermissionsUsers(
    pagination: {page: $page, pageSize: $pageSize, limit: $limit}
    sort: $sort
    filters: $filters
  ) {
    data {
      attributes {
        id
        firstName
        lastName
        confirmed
        phoneNumber
        email
        blocked
        createdAt
        role {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const VerificationInfoDocument = gql`
    query verificationInfo {
  verificationInfo {
    data {
      attributes {
        title
        info
        image {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useVerificationInfoQuery(baseOptions?: Apollo.QueryHookOptions<VerificationInfoQuery, VerificationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerificationInfoQuery, VerificationInfoQueryVariables>(VerificationInfoDocument, options);
      }
export function useVerificationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerificationInfoQuery, VerificationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerificationInfoQuery, VerificationInfoQueryVariables>(VerificationInfoDocument, options);
        }
export type VerificationInfoQueryHookResult = ReturnType<typeof useVerificationInfoQuery>;
export type VerificationInfoLazyQueryHookResult = ReturnType<typeof useVerificationInfoLazyQuery>;
export type VerificationInfoQueryResult = Apollo.QueryResult<VerificationInfoQuery, VerificationInfoQueryVariables>;