import { Typography } from "antd"
import { format } from "date-fns"
import { FC, useState } from "react"
import { useNavigate } from "react-router"
import { StatusTag } from "src/components/ui/StatusTag"
import { useUpdateNotificationMutation } from "src/graphql"
import './index.less'
import { NotificationsData } from "../.."
import { useCustomBreakpoint } from "src/hooks/useCustomBreakpoint"


export const NewAgencyVerificationNotification: FC<Partial<NotificationsData>> = (notification) => {
  const [isNewNotification, setIsNewNotification] = useState(notification.isNew)
  const navigate = useNavigate()
  const { isDesktop } = useCustomBreakpoint()


  const [updateNotification] = useUpdateNotificationMutation({
    onCompleted: () => {
      if (notification.refetchNotifications) {
        notification.refetchNotifications()
      }
      setIsNewNotification(false)
    }
  })

  const onUpdate = async () => {
    await updateNotification({
      variables: {
        id: notification.id as string,
        input: {
          isNew: false
        }
      }
    })
  }

  const formatDate = (date: Date) => {
    return format(date, 'dd/MM/yyyy, HH:mm');
  };
  
  const handleNavigate = () => {
    onUpdate()
    navigate(`/listings`)
  }

  const preparedData = {
    agencyName: notification.agencyVerificationRequest?.attributes?.agency?.data?.attributes?.agencyName,
    date: notification.date
  }

  return (
    <div className={'new-verification-notification'} onClick={handleNavigate}>
      <div className={'notification'}>
        <div>
          <Typography.Text className={'label-sm'}>
            {`Verification request from ${preparedData.agencyName}`}
          </Typography.Text>
        </div>


        <div className={'notification-info'}>
          <div>
            <Typography.Text>
              {formatDate(preparedData.date as Date)}
            </Typography.Text>
          </div>

            {!isDesktop && isNewNotification && <div>
            <StatusTag text={'new'} color={'success'} />
          </div>}
        </div>
      </div>

      {isDesktop && isNewNotification &&<div>
        <StatusTag text={'new'} color={'success'} />
      </div>}
    </div>
  )
}