import { FC } from "react"
import { icons, SvgIcon } from "../../icons/SvgIcon"
import { Button, ButtonProps } from "antd"

import "./index.less"

export const IconButton: FC<ButtonProps & { iconType: keyof typeof icons }> = ({ iconType, ...props }) => {
  return (
    <Button
      icon={<SvgIcon type={iconType} />}
      className={'icon-button'}
      {...props}
    />
  )
}
