import React, { FC } from "react"

import "./index.less"
import { Tag, TagProps } from "antd"

export const StatusTag: FC<TagProps & {text: string}> = ({ text, ...props }) => (
  <Tag
    className={'status-tag'}
    {...props}
  >
    {text}
  </Tag>
)
