import { Layout } from "antd";
import { FC, Suspense } from "react"
import { Outlet } from "react-router";

import { Header } from "../default";
import { useCustomBreakpoint } from "../../../hooks/useCustomBreakpoint";
import { MessagesBlock } from "../../section/MessagesSection/MessagesBlock/MessagesBlock"
import { UserRoleType } from "../../../types/global"
import { MessagesForClients } from "../../section/MessagesSection/MessagesBlock/MessagesForClients"
import { MessagesForAgent } from "../../section/MessagesSection/MessagesBlock/MessagesForAgent"
import "./MessagesLayout.less";
import { useAuth } from "src/context/AuthProvider";
import { PlaceholderWithImage } from "src/components/ui/PlaceholderWithImage";
import { useChatQuery, useEmptyStateQuery, useGetAllChatsQuery } from "src/graphql";
import { Loader } from '../Loader'
import { PageLoader } from "src/pages/PageLoader";
const MessagesLayout: FC = () => {
  const { isMobile } = useCustomBreakpoint()
  const { user, role } = useAuth()

  const { data: placeholder } = useEmptyStateQuery({
    variables: {
      filters: {
        type: { eq: 'MESSAGES' }
      }
    }
  })

  const { data } = useGetAllChatsQuery({
    variables: {
      filters: {
        or: [
          { agency: { id: { eq: user?.id } } },
          { client: { id: { eq: user?.id } } }
        ]
      }
    }
  })

  const userRole = role

  return (
    <Layout style={{ height: '100vh', overflow:  isMobile ? 'auto' : 'hidden' }}>
      <Header />
      {isMobile ? (
        <Suspense fallback={<PageLoader />}>
          {data?.chats?.data
              ? <Outlet />
              : <PlaceholderWithImage 
                  image={placeholder?.emptyStates?.data[0]?.attributes?.image?.data?.attributes?.url}
                  title={placeholder?.emptyStates?.data[0]?.attributes?.title}
                  description={placeholder?.emptyStates?.data[0]?.attributes?.subtitle}
                />
            }
        </Suspense>
      ) : (
        <div className={'messages'}>
          <MessagesBlock>
            {userRole === UserRoleType.User && (
              <MessagesForClients />
            )}
            {userRole === UserRoleType.Agency && (
              <MessagesForAgent />
            )}
          </MessagesBlock>

          <Suspense>
            {data?.chats?.data
              ? <Outlet />
              : <PlaceholderWithImage 
                  image={placeholder?.emptyStates?.data[0]?.attributes?.image?.data?.attributes?.url}
                  title={placeholder?.emptyStates?.data[0]?.attributes?.title}
                  description={placeholder?.emptyStates?.data[0]?.attributes?.subtitle}
                />
            }
          </Suspense>
        </div>
      )}
    </Layout>
  )
}

export { MessagesLayout }
