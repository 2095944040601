import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Header, Loader } from "../default"
import { PageLoader } from "src/pages/PageLoader"

const FooterlessLayout: FC = () => (
  <BaseLayout style={{ minHeight: '100vh' }}>
    <Header />
    <BaseLayout>
      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </BaseLayout>
  </BaseLayout>
)

export { FooterlessLayout }
