import { Image, Typography } from "antd"
import { FC } from "react"
import { SvgIcon } from "src/components/icons/SvgIcon"
import { ButtonType, CustomButton } from "src/components/ui/CustomButton"
import './UserMenu.less'
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "src/context/AuthProvider"
import { GlobalRoutes } from "src/pages/routesEums"
import { UserRoleType } from "src/types/global"

type Props = {
  onOpen: React.Dispatch<React.SetStateAction<boolean>>
  onRoleSwitch: () => void
  isOpen: boolean
}

export const UserMenu: FC<Props> = ({ onOpen, isOpen, onRoleSwitch }) => {
  const { logout, user, role: userRole, ableToSwitchRole } = useAuth()
  
  const navigate = useNavigate()
  
  const handleNavigate = (path: string) => () => (
    navigate(path)
  )
    
  const handleLogout = () => {
    logout()
    window.location.replace('/')
  }
  
  const getMenuData = (role: Maybe<string>) => {
    switch (role) {
      case 'admin':
      case 'user':
      case 'owner':
        return {
          name: `${user?.firstName} ${user?.lastName}`,
          avatar: user?.avatar?.data?.attributes?.url
        }
      case 'agency':
        return {
          name: user?.agency?.data?.attributes?.agencyName,
          avatar: user?.agency?.data?.attributes?.agencyLogo?.data?.attributes?.url
        }
      default:
        return null
    }
  }

  const dashboardPath = userRole === 'user' ? 'dashboard/saved-searches' : 'dashboard'
  return (
    <div
      onMouseLeave={() => onOpen(false)}
      onClick={() => onOpen(false)}
      className={`user-menu-wrapper ${isOpen ? 'open' : 'closed'}`}
    >
      <div className={'user-menu-container'}>
        <div className={'user-menu-inner-container'}>
          <div className={'user-info'}>
            <div className={'user-role-and-name'}>
              <Typography.Text className={'label-lg'}>
                {getMenuData(userRole)?.name}
              </Typography.Text>

              <Typography.Text>
                {userRole}
              </Typography.Text>
            </div>

            <div className={'user-avatar'}>
              <Image preview={false} src={getMenuData(userRole)?.avatar} />
            </div>
          </div>

          <div className={'launch-dashboard-button-wrapper'}>
            <Link to={
              location.pathname.includes('dashboard') && userRole === UserRoleType.User 
                ? GlobalRoutes.BuyProperties 
                : dashboardPath
              }
            >
              <CustomButton 
                className={'launch-dashboard-button'} 
                buttonType={ButtonType.PRIMARY} 
                buttonText={
                  location.pathname.includes('dashboard') && userRole === UserRoleType.User 
                  ? 'Find Property' 
                  : 'Launch Dashboard'
                } 
              />
            </Link>
          </div>

          {ableToSwitchRole && <div className={'help-center-button-wrapper menu-item'}>
            <CustomButton 
              iconPosition={'start'} 
              className={'menu-button'} 
              icon={<SvgIcon type={'switch'} />} 
              buttonType={ButtonType.BORDERLESS} 
              onClick={onRoleSwitch}
              buttonText={`Switch to ${userRole === 'user' ? 'Agency' : 'User'}`}
            />
          </div>}

          <div className={'sign-out-button-wrapper menu-item'}>
            <CustomButton 
              iconPosition={'start'} 
              className={'menu-button'} 
              icon={<SvgIcon type={'exit'} />} 
              buttonType={ButtonType.BORDERLESS} 
              buttonText={'Sign out'} 
              onClick={handleLogout}
            />
          </div>
        </div>
      </div>
    </div>
  )
}