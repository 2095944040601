import { FC } from "react"
import classNames from "classnames"
import { Avatar } from "antd"

import "./MessagesAvatar.less"

export const MessagesAvatar: FC<{ src: string, isOnline?: boolean }> = ({ src, isOnline = false }) => (
  <div className={classNames('messages-avatar-wrap', {'online': isOnline})}>
    <Avatar src={src} size={68} shape={'circle'} />
  </div>
)
