import { DefaultOptionType } from "antd/es/select";

export enum UserRoleType {
  User = 'user' ,
  Agency = 'agency',
  Admin = 'admin',
  Owner = 'owner',
}

export enum PropertyType {
  Overseas = 'overseas',
  Commerce = 'commerce',
  Residential = 'residential'
}

export enum PropertyStatus {
  FOR_SALE = 'FOR_SALE',
  FOR_RENT = 'FOR_RENT',
}

export type CustomOptionType<T> = DefaultOptionType & { value: T };