import { FC } from "react"
import classNames from "classnames"

import './Navigation.less'
import { NavLink } from "react-router-dom"

type Props = {
  menu: ComponentUiLink[]
}

const Navigation: FC<Props> = ({ menu }) => {
  return (
    <nav className={'navigation-menu'}>
      <ul className={'navigation-menu-list'}>
        {menu?.map((item, index) => (
          <li key={item.id} className={'navigation-list-item'}>
            <NavLink 
              to={item.url} 
              className={(props) => {
                return classNames(
                  'navigation-item-link',
                  'paragraph-md',
                  { 'active-link': props.isActive },
                  { 'label-md': props.isActive }
                );
              }}
            >
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Navigation
