import { Button, Drawer, Dropdown, MenuProps, Typography } from "antd"
import { Fragment, useState } from "react"
import { useNotificationsContext } from "src/context/NotificationProvider"
import { SvgIcon } from "../icons/SvgIcon"
import { RequestStatusUpdate } from "./UserNotifications/RequestStatusChange"
import './index.less'
import { NewAgencyVerificationNotification } from "./AdminNotifications/NewAgencyVerification"
import { VerificationRequest } from "./AgencyNotifications/VerificationRequest"
import { NewRequestNotification } from "./AgencyNotifications/PropertyViewRequest"
import NotificationCounter from "../ui/NotificationCounter"
import { ApolloQueryResult } from "@apollo/client"
import { useCustomBreakpoint } from "src/hooks/useCustomBreakpoint"

export interface NotificationsData {
  id: Maybe<string>,
  type: Maybe<EnumNotificationType>
  date: Maybe<Date>
  isNew: Maybe<boolean>
  refetchNotifications: (() => Promise<ApolloQueryResult<NotificationsQuery>>) | undefined
  propertyViewRequest?: Maybe<PropertyViewRequestEntity>
  agencyVerificationRequest?: Maybe<AgencyVerificationRequestEntity>
}

export const Notifications = () => {
  const [open, setIsOpen] = useState(false)
  const { notificationsCount } = useNotificationsContext()
  const { appNotifications, refetch } = useNotificationsContext()

  const { isDesktop } = useCustomBreakpoint()

  const preparedNotificationsData: Maybe<NotificationsData[]> = appNotifications?.notifications?.data.map(({ attributes, id }) => ({
    id: id,
    type: attributes?.type,
    date: attributes?.createdAt,
    isNew: attributes?.isNew,
    refetchNotifications: refetch,
    propertyViewRequest: attributes?.property_view_request?.data as PropertyViewRequestEntity,
    agencyVerificationRequest: attributes?.agency_verification_request?.data,
  }))

  const newRequestsNotifications = preparedNotificationsData?.map(notification => {
    let labelComponent;
    switch (notification.type) {
      case 'AGENCY_VERIFICATION_REQUEST_CREATED':
        labelComponent = (
          <NewAgencyVerificationNotification 
            {...notification}
          />
        );
        break
      case 'AGENCY_VERIFICATION_REQUEST_UPDATED':
        labelComponent = (
          <VerificationRequest
            {...notification}
          />
        ) 
        break
      case 'VIEW_REQUEST_CREATED':
        labelComponent = (
          <NewRequestNotification 
            {...notification} 
          />
        );
        break;
      case 'VIEW_REQUEST_UPDATED':
        labelComponent = (
          <RequestStatusUpdate
            {...notification} 
          />
        );
        break;
      default:
        labelComponent = null;
    }
  
    return {
      key: notification.id as string,
      label: labelComponent
    };
  });


  const items: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: <Typography.Text className={'label-lg'}>Notifications</Typography.Text>,
      children: newRequestsNotifications
    }
  ]

  const handleOpenDropdown = () => (
    setIsOpen(prevState => !prevState)
  )

  return (
    <Fragment>
      {isDesktop
        ? <Dropdown 
            menu={{ items }} 
            onOpenChange={handleOpenDropdown} 
            rootClassName={'notifications-dropdown'}
          >
            <div className={'notification-button-wrapper'}>
              <Button 
                style={{ border: 'none', boxShadow: 'none' }}
                icon={<SvgIcon type={open ? 'notification-active' : 'notification'} />}
              />

            {notificationsCount ? <NotificationCounter count={notificationsCount || 0} /> : ''} 
            </div>
          </Dropdown>
        : <Fragment>
            <div className={'notification-button-wrapper'}>
              <Button
                onClick={() => setIsOpen(prevState => !prevState)} 
                style={{ border: 'none', boxShadow: 'none' }}
                icon={<SvgIcon type={open ? 'notification-active' : 'notification'} />}
              />

              {notificationsCount ? <NotificationCounter count={notificationsCount || 0} /> : ''} 
            </div>

            <Drawer
              open={open}
              placement={'right'}
              onClose={() => setIsOpen(false)}
              rootClassName={'notifications-drawer'}
            >
              {newRequestsNotifications?.map(notification => notification.label)}
              
            </Drawer>
          </Fragment>
      }
    </Fragment>
  )
}

