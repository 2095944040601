import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, Fragment, ReactNode, useRef } from "react";
import './index.less'

export enum ButtonType {
  PRIMARY = 'primary',
  BORDERLESS = 'borderless',
  TRANSPARENT = 'transparent',
  WHITE = 'white',
  DISABLED = 'disabled'
}

type ButtonProps  = {
  buttonType: ButtonType
  buttonText?: Maybe<string> | boolean | ReactNode
  icon?: React.ReactNode
  target?: React.HTMLAttributeAnchorTarget | undefined
  iconPosition?: 'start' | 'end'
} & Partial<ButtonHTMLAttributes<HTMLButtonElement>>

type AnchorProps = {
  href?: string
  target?: React.HTMLAttributeAnchorTarget | undefined
} & Partial<AnchorHTMLAttributes<HTMLAnchorElement>>

type Props = ButtonProps & AnchorProps;

export const CustomButton: FC<Props> = ({
  iconPosition,
  className,
  href,
  target,
  icon,
  buttonType,
  buttonText,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useRef<HTMLAnchorElement>(null);

  return (
    <Fragment>
      {!href 
        ? <button ref={buttonRef} className={`${className} custom-button type-${buttonType}`} {...props}>
            <div className={'inner-wrapper'}>
              {icon && <span style={{ order: iconPosition === 'start' ? 1 : 2, display: 'flex' }}>
                {icon}
              </span>}
              {buttonText && <span style={{ order: iconPosition === 'start' ? 2 : 1 }} className={'paragraph-md button-text'}>
                {buttonText}
              </span>}
            </div>
          </button>
        : <a ref={anchorRef} href={href} target={target} className={`${className} custom-button type-${buttonType}`} {...props}>
            <div className={'inner-wrapper'}>
              {icon &&<span style={{ order: iconPosition === 'start' ? 1 : 2 }}>
                {icon}
              </span>}
              {buttonText && <span style={{ order: iconPosition === 'start' ? 2 : 1 }} className={'paragraph-md button-text'}>
                {buttonText}
              </span>}
            </div>
          </a>
      }
    </Fragment>
  );
};
