import { useEffect, useState } from 'react';
import { socket } from './index';
import { useAuth } from 'src/context/AuthProvider';

export const useSocket = () => {
  const { user } = useAuth();

  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (user?.id && !connected) {
      socket.auth = { userId: user.id };
      socket.connect();
      setConnected(true);
    }

    return () => {
      if (connected) {
        socket.disconnect();
        setConnected(false);
      }
    };
  }, [user?.id, connected]);

  return socket;
};
