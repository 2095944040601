import { FC, HTMLProps } from 'react'
import './index.less'
import classNames from 'classnames'

type Props = {
  size?: number
  firstName: Maybe<string>
  lastName: Maybe<string>
  classname?: string | string[]
} & HTMLProps<HTMLDivElement>

export const AvatarPlaceholder: FC<Props> = ({ 
  size, 
  firstName, 
  lastName, 
  classname,
  ...props
}) => {
  return (
    <div {...props} className={classNames('avatar-placeholder', classname)}>
      <div className={'avatar-circle'}>
        <span className={'avatar-initials'}>
          {firstName && lastName
            ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
            : ''
          }
        </span>
      </div>
    </div>
  )
}