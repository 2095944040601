import { Col, Row, Typography } from "antd"
import { Logo } from "src/components/logo"
import { ReactComponent as BurgerIcon } from '/src/assets/icons/BurgerMenu.svg'
import { ButtonType, CustomButton } from "src/components/ui/CustomButton"
import { FC } from "react"
import { useCustomBreakpoint } from "src/hooks/useCustomBreakpoint"
import './Header.less'
import { useLocation, useNavigate } from "react-router"
import { GlobalRoutes } from "src/pages/routesEums"

const Header: FC = () => {
  const { isDesktop } = useCustomBreakpoint()
  const location = useLocation()

  const navigate = useNavigate()

  const buttonTypes = {
    signInCase: {
      text: "Already a member?",
      button: {
        href: GlobalRoutes.SignIn,
        text: 'Sign In'
      }
    },
    signUpCase: {
      text: "Don't have an account?",
      button: {
        href: GlobalRoutes.SignUp,
        text: 'Sign Up'
      }
    }
  }

  const headerButton = location.pathname === GlobalRoutes.SignIn ? buttonTypes['signUpCase'] : buttonTypes['signInCase']
  return (
    <Row className={'auth-header'}>
      <Col className={'header-logo-wrapper'}>
        {isDesktop ? <Logo classname={'header-logo'} /> : <BurgerIcon />}
      </Col>

      {isDesktop && <Col className={'header-button-wrapper'}>
        <Typography.Text className={'paragraph-md'}>
          {headerButton.text}
          <CustomButton 
            className={'header-button'} 
            onClick={() => navigate(headerButton.button.href, { replace: true })} 
            buttonText={headerButton.button.text} 
            buttonType={ButtonType.WHITE} 
          />
        </Typography.Text>
      </Col>}
    </Row>
  )
}

export { Header }
