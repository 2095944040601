import { Divider, Drawer, Image, Typography } from "antd"
import { FC, useState } from "react"
import './UserMenuDrawer.less'
import { ButtonType, CustomButton } from "src/components/ui/CustomButton"
import { SvgIcon } from "src/components/icons/SvgIcon"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "src/context/AuthProvider"
import { useRolesQuery, useUpdateUserMutation } from "src/graphql"

type Props = {
  isOpen: boolean
  onOpen: React.Dispatch<React.SetStateAction<boolean>>
  onRoleSwitch: () => void
}

export const UserMenuDrawer: FC<Props> = ({ isOpen, onOpen, onRoleSwitch }) => {
  const { logout, user, role: userRole, ableToSwitchRole } = useAuth()
  const navigate = useNavigate()

  const getMenuData = (role: Maybe<string>) => {
    switch (role) {
      case 'admin':
      case 'user':
      case 'owner':
        return {
          name: `${user?.firstName} ${user?.lastName}`,
          avatar: user?.avatar?.data?.attributes?.url
        }
      case 'agency':
        return {
          name: user?.agency?.data?.attributes?.agencyName,
          avatar: user?.agency?.data?.attributes?.agencyLogo?.data?.attributes?.url
        }
      default:
        return null
    }
  }
    
  const handleLaunchDashboard = () => {
    navigate(userRole === 'user' ? '/dashboard/saved-searches' : '/dashboard', { replace: true })
    onOpen(false)
  }
  return (
    <Drawer 
      open={isOpen}
      mask={false}
      rootClassName={'user-menu-drawer'}
      onClose={() => onOpen(false)}
    >
      <div className={'user-info'}>
        <Image rootClassName={'user-avatar'} preview={false} src={getMenuData(userRole)?.avatar} />

        <Typography.Text className={'user-name label-lg'}>
          {getMenuData(userRole)?.name}
        </Typography.Text>

        <Typography.Text className={'user-role'}>
          {userRole}
        </Typography.Text>
      </div>

      <div className={'launch-dashboard-wrapper'}>
        <CustomButton 
          onClick={handleLaunchDashboard}
          className={'launch-dashboard-button'} 
          buttonType={ButtonType.PRIMARY} 
          buttonText={'Launch Dashboard'} 
        />
      </div>

      <Divider style={{ margin: '24px 0' }} />

      <div className={'buttons-container'}>
        {ableToSwitchRole && <div className={'menu-button-wrapper'}>
          <CustomButton 
            className={'menu-button'} 
            iconPosition={'start'} 
            icon={<SvgIcon type={'switch'} />} 
            buttonType={ButtonType.BORDERLESS} 
            onClick={onRoleSwitch}
            buttonText={`Switch to ${userRole === 'user' ? 'Agency' : 'User'}`}
          />
        </div>}

        <div className={'menu-button-wrapper'}>
          <CustomButton 
            className={'menu-button'} 
            onClick={logout}
            iconPosition={'start'} 
            icon={<SvgIcon type={'exit'} />} 
            buttonText={'Sign out'} 
            buttonType={ButtonType.BORDERLESS} 
          />
        </div>
      </div>
    </Drawer>
  )
}