import { useMediaQuery } from "react-responsive"

const useCustomBreakpoint = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1200px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  return { isDesktop, isTablet, isMobile }
}

export { useCustomBreakpoint }