import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd"
import { createContext, memo, useContext, type FC, type PropsWithChildren, useState, useEffect } from "react"
import { version } from "../../../package.json"
import introspection from "../../graphql"
import { NotificationsProvider } from "src/context/NotificationProvider"
import { AuthProvider, useAuth } from "src/context/AuthProvider"
import { APIProvider } from "@vis.gl/react-google-maps"
import theme from "../../themes"
import Router from "../../pages"
import { useNavigate } from "react-router"
import { GlobalRoutes } from "src/pages/routesEums"
import { SubscriptionProvider } from "src/context/SubscriptionProvider"

type ContextProps = {
  app: { 
    version: string
    isPropertyListsModalOpen: boolean
    openSaveToListModal: () => void
    propertyId: Maybe<string>
    setPropertyId: React.Dispatch<React.SetStateAction<string>> 
    registrationStep: number
    setRegistrationStep: React.Dispatch<React.SetStateAction<number>>
  }
}

const app: ContextProps["app"] = { 
  version,
  isPropertyListsModalOpen: false,
  openSaveToListModal: () => null,
  setPropertyId: () => '',
  propertyId: '',
  setRegistrationStep: () => 0,
  registrationStep: 1,
}

const Context = createContext({ app })

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  const [isPropertyListsModalOpen, setIsPropertyListsModalOpen] = useState(false)
  const [propertyId, setPropertyId] = useState('')
  const [registrationStep, setRegistrationStep] = useState(1)
  const { user } = useAuth()
    
  const openSaveToListModal = () => {
    if (!user?.id) {
      window.location.replace(GlobalRoutes.SignIn);
    } else {
      setIsPropertyListsModalOpen(prevState => !prevState)
    }
  }

  return <Context.Provider value={{ 
    ...props, 
    app: { 
      ...props.app, 
      isPropertyListsModalOpen, 
      openSaveToListModal, 
      propertyId, 
      setPropertyId, 
      registrationStep,
      setRegistrationStep
    } 
  }}>{children}</Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
    headers: localStorage.getItem('auth_token')
      ? { Authorization: `Bearer ${localStorage.getItem('auth_token')}` }
      : {},
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <SubscriptionProvider>
        <ContextProvider app={app}>
        <NotificationsProvider>
          <ConfigProvider theme={theme}>
            <APIProvider libraries={['places']} apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
              <Router />
            </APIProvider>
          </ConfigProvider>
        </NotificationsProvider>
        </ContextProvider>
      </SubscriptionProvider>
    </AuthProvider>
  </ApolloProvider>
))

export { useApp }

export default App
