import { FC } from "react"
import { Row, Space, Typography } from "antd"

import { MessagesAvatar } from "../MessagesAvatar/MessagesAvatar"
import { VSpace } from "../../../ui/VSpace/VSpace"
import "./MassageCard.less"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import classNames from "classnames"

type MessageCardProps = {
  title: string,
  text: string,
  linkTo: string,
  avatarSrc: string,
  isOnline?: boolean,
  newMessagesCount?: number,
}
export const MessageCard: FC<MessageCardProps> = ({ title, text, linkTo, newMessagesCount = 0, avatarSrc, isOnline = false }) => {
  const {pathname} = useLocation()

  return (
    <Link
      to={linkTo}
      className={classNames('message-link')}
    >
      <Row align={'middle'} className={classNames('message-card', { "active": pathname === linkTo })} wrap={false}>
        <MessagesAvatar src={avatarSrc} isOnline={isOnline} />
        <VSpace size={4} className={'message-card-info'} style={{width: '100%'}}>
          <Row align={'middle'} justify={'space-between'} style={{width: '100%'}}>
            <Typography.Title level={4} className={'message-card-title'}>
              {title}
            </Typography.Title>
           <div>
              {!!newMessagesCount && (
                <div className={'new-messages-count'}>
                  <span>{newMessagesCount}</span>
                </div>
              )}
           </div>
          </Row>
          <Typography.Text className={'message-card-text'}>
            {text}
          </Typography.Text>
        </VSpace>
      </Row>
    </Link>
  )
}
