import { FC } from "react"
import './index.less'

type Props = {
  count: number
}

const NotificationCounter: FC<Props> = ({ count }) => {

  return (
    <div className={'notification-counter '}>
      <span className={'badge'}>{count}</span>
    </div>
  )
}

export default NotificationCounter