import { Drawer, Form, Input, Modal, Typography } from "antd"
import { FC, Fragment } from "react"
import { ButtonType, CustomButton } from "../../ui/CustomButton"
import { useCreateUserFavoriteListMutation, useUserFavoriteListsQuery } from "src/graphql"
import { ModalType } from "../../layout/default/Header"
import './CreateNewListModal.less'
import { useCustomBreakpoint } from "src/hooks/useCustomBreakpoint"
import { useAuth } from "src/context/AuthProvider"

type Props = {
  isModalOpen: boolean
  setModalType: React.Dispatch<React.SetStateAction<ModalType>> 
  toggleModal: () => void
}

export const CreateNewListModal: FC<Props> = ({ isModalOpen, setModalType, toggleModal }) => {
  const [form] = Form.useForm()

  const { user } = useAuth()

  const { data: lists, refetch } = useUserFavoriteListsQuery({ variables: { filters: { user: { id: { eq: user?.id } } } } })
  const [createList] = useCreateUserFavoriteListMutation()
  const { isDesktop } = useCustomBreakpoint()


  const onFinish = async () => {
    try {
      await createList({
        variables: {
          data: {
            listName: form.getFieldValue('listName'),
            user: user?.id,
            properties: []
          }
        }
      })
      refetch()
      setModalType('lists')
    } catch (error) {
      console.log(error);
      
    }
  }

  const handleCloseModal = () => {
    toggleModal()
    setModalType('lists')
  }

  return (
    <Fragment>
      {isDesktop 
        ? <Modal
            centered
            rootClassName={'create-list-modal'}
            footer={false}
            closable={true}
            onCancel={handleCloseModal}
            title={
            <Typography.Text className={'heading-5'}>
              Create New List 
            </Typography.Text>
          } 
            open={isModalOpen}
          >
            <Form
              onFinish={onFinish}
              form={form}
              layout={'vertical'}
            >
              <Form.Item
                className={'input-wrapper'}
                label={<Typography.Text className={'label-sm'}>
                    List name
                  </Typography.Text>
                }
                name={'listName'}
              >
                <Input />
              </Form.Item>
      
              <Form.Item className={'button-wrapper'}>
                <CustomButton buttonText={'Create'} buttonType={ButtonType.PRIMARY} />
              </Form.Item>
            </Form>
          </Modal>
        : <Drawer
            open={isModalOpen}
            placement={'bottom'}
            title={<Typography.Text className={'label-lg'}>Create New List</Typography.Text>}
            rootClassName={'create-list-drawer'}
          >
            <Form
              onFinish={onFinish}
              form={form}
              layout={'vertical'}
            >
              <Form.Item
                className={'input-wrapper'}
                label={<Typography.Text className={'label-sm'}>
                    List name
                  </Typography.Text>
                }
                name={'listName'}
              >
                <Input placeholder={'Enter list name'} />
              </Form.Item>
      
              <Form.Item>
                <CustomButton type={'submit'} buttonText={'Create'} buttonType={ButtonType.PRIMARY} />
              </Form.Item>
            </Form>
          </Drawer>
      }
    </Fragment>
  )
}