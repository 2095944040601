import { Drawer } from "antd"
import { FC } from "react"
import { Logo } from "src/components/logo"
import { MobileNavigation } from "src/components/menu/MobileNavigation"
import './index.less'
import { Link } from "react-router-dom"
import { GlobalRoutes } from "src/pages/routesEums"

type Props = {
  open: boolean
  menu: ComponentUiLink[]
  onClose: React.Dispatch<React.SetStateAction<boolean>>
}

export const NavigationDrawer: FC<Props> = ({ open, menu, onClose }) => {

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Drawer
      open={open}
      placement={'left'}  
      rootClassName={'navigation-drawer'}
      onClose={handleClose}
      title={<Logo onClick={handleClose} svgProps={{ height: 40, width: 120 }} />}
    > 
      <MobileNavigation onMenuClose={handleClose} menu={menu} />
    </Drawer>
  )
}