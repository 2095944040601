export enum GlobalRoutes {
  SignUp = '/sign-up',
  SignIn = '/sign-in',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Home = "/",
  Agencies = "/agencies",
  Agency = "/agencies/:slug",
  BuyProperties = "/buy-property",
  RentProperties = "/rent-property",
  BuyProperty = "/buy-property/:id",
  RentProperty = "/rent-property/:id",
  Blog = "/blog/:slug",
  NotFound = "*",
  Comparison = "/comparison",
  Error = "/error",
}

export enum AdminRoutes {
  Dashboard = "/dashboard",
  UsersAndPermissions = "/users-and-permissions",
  Listings = "/listings",
  Settings = "/settings",
  BlogManager = "/blog-manager",
}

export enum AgencyRoutes {
  Dashboard = "/dashboard",
  // BuyProperties = "/buy-property",
  Listings = "/listings",
  RentListings = "/rent-listings",
  Settings = "/settings",
  Messages = "/messages",
  Message = "/messages/:id",
  RequestsView = "/request-view",
  RequestView = "/request-view/:id",
}

export enum UserRoutes {
  SavedSearches = "/dashboard/saved-searches",
  PropertiesLists = "/dashboard/properties-lists",
  PropertiesList = "/dashboard/properties-lists/:id",
  Settings = "/dashboard/settings",
  Messages = "messages",
  Message = "/messages/:id",
  RequestView = "/dashboard/request-view",
}