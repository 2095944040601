import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      colorBgHeader: "unset",
      colorBgBody: "unset",
    },
    Typography: {
      fontSizeHeading1: 56,
      fontSizeHeading2: 48,
      fontSizeHeading3: 40,
      fontSizeHeading4: 32,
      fontSizeHeading5: 24,
    },
    Input: {
      fontFamily: "Inter",
      fontSize: 16,
      fontWeightStrong: 400,
      colorText: '#111214',
      colorTextPlaceholder: '#111214',
    },
    Checkbox: {
      colorPrimaryActive: '#00819C',
      colorPrimary: '#00819C',
      colorIconHover: '#FFF',
      colorPrimaryHover: '#00819C',
    },
    Radio: {
      colorPrimaryActive: '#00819C',
      colorPrimary: '#00819C',
      colorIconHover: '#FFF',
      colorPrimaryHover: '#00819C',
    },
    Pagination: {
      colorPrimary: '#111214',
      colorPrimaryHover: 'gray'
    },
    Tag: {
      colorSuccess: '#377B10',
      colorSuccessBg: '#F2F2F2',
      defaultBg: '#F2F2F2',
      defaultColor: '#2E3034',
    },
    Table: {
      colorPrimary: '#111214',
      colorPrimaryBg: '#111214',
    },
    Button: {
      colorPrimary: '#111214',
      colorPrimaryHover: '#111214',
    },
    DatePicker: {
      colorPrimary: '#111214',
    }
  },
}

export default theme
