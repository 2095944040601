import { Typography } from 'antd'
import { Image } from 'antd/lib'
import { FC } from 'react'
import './index.less'

type Props = {
  image: Maybe<string>
  title?: Maybe<string> | JSX.Element
  description?: Maybe<string>
}

export const PlaceholderWithImage: FC<Props> = ({ image, title, description }) => {
  return (
    <div className={'empty-state-wrapper'}>
      <Image 
        preview={false}
        src={image || ''}
        className={'empty-state-image'}
      />

      {
      typeof title === 'string' && 
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {title}
        </Typography.Title>
      }

      {typeof title === 'object' && title}

      {description && <Typography.Text className={'paragraph-sm'}>
        {description}
      </Typography.Text>}
  </div>
  )
}