import { Button, Drawer, Image, Modal, Typography } from "antd"
import { FC, Fragment, useEffect, useState } from "react"
import { useGetPropertyBuIdQuery, useUpdateUserFavoriteListMutation, useUserFavoriteListsQuery } from "src/graphql"
import { ButtonType, CustomButton } from "../../ui/CustomButton"
import './AllFavoriteListsModal.less'
import { ModalType } from "../../layout/default/Header"
import { useApp } from "../../app"
import { SvgIcon } from "../../icons/SvgIcon"
import { useCustomBreakpoint } from "src/hooks/useCustomBreakpoint"
import imagePlaceholder from '/src/assets/images/placeholder.jpg'
import { useAuth } from "src/context/AuthProvider"

type Props = {
  isModalOpen: boolean
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const AllFavoriteListsModal: FC<Props> = ({ isModalOpen, setModalType, toggleModal }) => {
  const { propertyId } = useApp().app 
  const [selectedList, setSelectedList] = useState<string>()
  const { user } = useAuth()

  const { data: property } = useGetPropertyBuIdQuery({ variables: { id: propertyId as string } })

  const [listData, setListData] = useState<string[]>()
  const [allLists, setAllLists] = useState<UserFavoriteListEntity[]>()

  const { isDesktop } = useCustomBreakpoint()

  const { data: lists, refetch } = useUserFavoriteListsQuery({ variables: { filters: { user: { id: { eq: user?.id as string } } } } })
  const [updateList] = useUpdateUserFavoriteListMutation()

  useEffect(() => {
    setAllLists(lists?.userFavoriteLists?.data as UserFavoriteListEntity[])
  }, [lists?.userFavoriteLists?.data])
  
  useEffect(() => {
    const currentList = allLists?.filter(list => list.id === selectedList)
    const listPropsIds = currentList && currentList[0]?.attributes?.properties?.data.map(prop => prop.id)
    const newListItem = property?.property?.data?.id
    
    let updatedData = []
    
    if (listPropsIds?.includes(newListItem)) {
      updatedData = [...(listPropsIds?.filter(id => id !== newListItem) ?? [])]
    } else {
      updatedData = [newListItem, ...(listPropsIds ?? [])]
    }

    if (updatedData) {
      setListData(updatedData as string[])
    }

  }, [property?.property?.data?.id, selectedList])

  const handleCloseModal = () =>  {
    toggleModal(prevState => !prevState)
    setSelectedList('')
  }

  const onFinish = async () => {
      updateList({
        variables: {
          id: selectedList as string,
          data: {
            properties: listData
          }
        }
      })
      
    handleCloseModal()
    setListData([])
    
    await refetch();
  }

  const ifSelectedStyles = { 
    backgroundColor: '#00819C', 
    borderColor: '#00819C' 
  }
  
  const handleListClick = (listId: string) => {
    if (listId) {
      setSelectedList(listId)
    }
  };

  return (
    <Fragment>
      {isDesktop 
        ? <Modal
            centered
            onCancel={handleCloseModal}
            rootClassName={'all-favorite-lists-modal'}
            footer={
              <div className={'modal-buttons-wrapper'}>
                <CustomButton onClick={() => setModalType('createList')} style={{ paddingLeft: 0 }} buttonText={'Create New List'} buttonType={ButtonType.BORDERLESS} />
        
                <CustomButton onClick={(onFinish)} buttonText={'Save'} buttonType={ButtonType.PRIMARY} />
              </div>
            }
            destroyOnClose={true}
            title={
              <Typography.Text className={'heading-5'}>
                Save to list
              </Typography.Text>
            } 
            open={isModalOpen}
          >
            <div className={'modal-lists'}>
              {allLists?.map((list, index) => {
                const isSelected = selectedList === list.id

                return (
                  <div key={list.attributes?.listName} className={'list-item'}>
                    <Typography.Text className={'label-lg'}>
                      {list.attributes?.listName}
                    </Typography.Text>

                    <div className={'image-wrapper'}>
                      {list.attributes?.properties?.data[0]?.attributes?.media?.data[0]?.attributes?.url
                        ?<Image 
                            height={148}
                            preview={false} 
                            src={list.attributes?.properties?.data[0]?.attributes?.media?.data[0]?.attributes?.url} 
                          />
                        : <div className={"image-placeholder"}></div>
                      }
                    </div>
        
                    <Button 
                      style={isSelected ? ifSelectedStyles : {}} 
                      onClick={() => handleListClick(list.id as string)} 
                      className={'add-button'} 
                      icon={<SvgIcon type={isSelected ? 'checkmark' : 'plus'} />} 
                    />
                  </div>
                )
              })}
            </div>
          </Modal>
        : <Drawer
            title={<Typography.Text className={'label-lg'}>Save to List</Typography.Text>}
            open={isModalOpen} 
            placement={'bottom'} 
            footer={
              <div className={'drawer-buttons-wrapper'}>
                <CustomButton className={'drawer-button'} onClick={onFinish} buttonText={'Save'} buttonType={ButtonType.PRIMARY} />

                <CustomButton className={'drawer-button'} onClick={() => setModalType('createList')} style={{ paddingLeft: 0 }} buttonText={'Create New List'} buttonType={ButtonType.BORDERLESS} />
              </div>
            }
            onClose={() => toggleModal(prevState => !prevState)}
            rootClassName={'all-favorite-lists-drawer'}
          >
            <div className={'drawer-lists'}>
              {allLists?.map((list, index) => {
                const isSelected = selectedList === list.id

                return (
                  <div key={list.attributes?.listName} className={'list-item'}>
                    <Typography.Text className={'label-lg'}>
                      {list.attributes?.listName}
                    </Typography.Text>
                    <div className={'list-image-wrapper'}>
                      <img src={list.attributes?.properties?.data[0]?.attributes?.media?.data[0]?.attributes?.url || imagePlaceholder} />
                    </div>
        
                    <Button 
                      style={isSelected ? ifSelectedStyles : {}} 
                      onClick={() => handleListClick(list.id as string)} 
                      className={'add-button'} 
                      icon={<SvgIcon type={isSelected ? 'checkmark' : 'plus'} />} 
                    />
                  </div>
                )
              })}
            </div>
          </Drawer>
      }
    </Fragment>
  )
}