import { Typography } from "antd"
import { format } from "date-fns"
import { FC, useState } from "react"
import { useNavigate } from "react-router"
import { StatusTag } from "src/components/ui/StatusTag"
import { useUpdateNotificationMutation } from "src/graphql"
import './index.less'
import { NotificationsData } from "../.."

export const VerificationRequest: FC<Partial<NotificationsData>> = (notification) => {
  const [isNewNotification, setIsNewNotification] = useState(notification.isNew)
  const navigate = useNavigate()

  const [updateNotification] = useUpdateNotificationMutation({
    onCompleted: () => {
      if (notification.refetchNotifications) {
        notification.refetchNotifications()
      }
      setIsNewNotification(false)
    }
  })

  const onUpdate = async () => {
    await updateNotification({
      variables: {
        id: notification.id as string,
        input: {
          isNew: false
        }
      }
    })
  }

  const requestData = {
    status: notification.agencyVerificationRequest?.attributes?.status
  }

  const formatDate = (date: Date) => {
    return format(date, 'dd/MM/yyyy, HH:mm');
  };
  
  const handleNavigate = () => {
    onUpdate()
  }

  
  const getTagColor = (status: Maybe<EnumAgencyverificationrequestStatus>) => {
    if (!status) {
      return;
    }
  
    switch (status) {
      case 'verified':
        return 'success';
      case 'pending':
        return 'default';
      case 'unverified':
        return 'red';
      default:
        return 'default';
    }
  };

  return (
    <div className={'new-request-notification'} onClick={handleNavigate}>
      <div>
        <div className={'notification-info'}>
          <Typography.Text className={'label-sm'}>
            {`Your verification request status was update to`}
          </Typography.Text>
          <StatusTag className={'status-tag'} text={requestData.status!} color={getTagColor(requestData.status)} />
        </div>


        <div className={'notification-date'}>
          <div>
            <Typography.Text>
              {formatDate(notification.date as Date)}
            </Typography.Text>
          </div>
        </div>
      </div>

      {isNewNotification && <div>
        <StatusTag text={'new'} color={'success'} />
      </div>}
    </div>
  )
}