import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useMeQuery, useSubscriptionsLazyQuery } from "src/graphql";

type SubscriptionStatus =  'active' | 'trialing' | 'incomlete' | 'canceled'
type SubscriptionType = EnumSubscriptionSubscriptiontype | undefined

type SubscriptionContextProps = {
  subscriptionStatus: SubscriptionStatus;
  hasSubscription: boolean;
  subscriptionType: Maybe<SubscriptionType>;
}
type SubscriptionProviderProps = PropsWithChildren<Partial<SubscriptionContextProps>>

const SubscriptionContext = createContext<SubscriptionProviderProps | undefined>(undefined);

export function useAgencySubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useAgencySubscription must be used within an SubscriptionProvider');
  }
  return context;
}

export const SubscriptionProvider: FC<SubscriptionProviderProps> = ({ children }) => {
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState<Maybe<SubscriptionType>>(undefined);

  const { data: user } = useMeQuery({ fetchPolicy: 'no-cache' });

  const [getActiveSubscription, { data }] = useSubscriptionsLazyQuery({
    variables: {
      filters: {
        user: { id: { eq: user?.me?.id } },
        or: [
          { status: { eq: 'active' } },
          { status: { eq: 'trialing' } }
        ]
      }
    }
  })

  useEffect(() => {
    getActiveSubscription()
  }, [])

  useEffect(() => {
    if (data?.subscriptions?.data?.length) {
      setHasSubscription(true);
      setSubscriptionType(data?.subscriptions?.data[0].attributes?.subscriptionType);
    }
  }, [data?.subscriptions?.data])

  return (
    <SubscriptionContext.Provider 
      value={{
        subscriptionStatus: 'active', 
        hasSubscription: hasSubscription, 
        subscriptionType: subscriptionType
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}