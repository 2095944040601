import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header, Loader } from "."
import { PageLoader } from "src/pages/PageLoader"

const Layout: FC = () => (
  <BaseLayout style={{ position: 'relative' }}>
    <Header />
    <BaseLayout style={{ minHeight: '100vh' }}>
      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </BaseLayout>
    <Footer />
  </BaseLayout>
)

export { Layout }
