import { FC, SVGProps } from "react";
import { ReactComponent as X } from '/src/assets/icons/x.svg'
import { ReactComponent as Facebook } from '/src/assets/icons/facebook.svg'
import { ReactComponent as LinkedIn } from '/src/assets/icons/linkedin.svg'
import { ReactComponent as LogoMin } from '/src/assets/icons/LogoMinimalized.svg'
import { ReactComponent as SearchIcon } from '/src/assets/icons/SearchIcon.svg'
import { ReactComponent as Arrow } from '/src/assets/icons/ArrowDown.svg'
import { ReactComponent as ArrowBack } from '/src/assets/icons/arrow-back.svg'
import { ReactComponent as ArrowPrev } from '/src/assets/icons/arrow-prev.svg'
import { ReactComponent as ArrowNext } from '/src/assets/icons/arrow-next.svg'
import { ReactComponent as Star } from '/src/assets/icons/star.svg'
import { ReactComponent as XMarkCircle } from '/src/assets/icons/x-mark_circle.svg'
import { ReactComponent as CheckmarkCircle } from '/src/assets/icons/checkmark_circle.svg'
import { ReactComponent as CheckmarkCircleSm } from '/src/assets/icons/checkmark_circle_mini.svg'
import { ReactComponent as Scales } from '/src/assets/icons/scales.svg'
import { ReactComponent as ScalesActive } from '/src/assets/icons/scales-active.svg'
import { ReactComponent as Heart } from '/src/assets/icons/heart.svg'
import { ReactComponent as Bed } from '/src/assets/icons/bed.svg'
import { ReactComponent as RoomSquare } from '/src/assets/icons/room_square.svg'
import { ReactComponent as ReviewStar } from '/src/assets/icons/review_star.svg'
import { ReactComponent as ArrowIcon } from '/src/assets/icons/arrow.svg'
import { ReactComponent as PoundsIcon } from '/src/assets/icons/pounds.svg'
import { ReactComponent as ScheduleIcon } from '/src/assets/icons/schedule.svg'
import { ReactComponent as FilterIcon } from '/src/assets/icons/filter.svg'
import { ReactComponent as CheckmarkIcon } from '/src/assets/icons/checkmark.svg'
import { ReactComponent as MapIcon } from '/src/assets/icons/map.svg'
import { ReactComponent as HouseIcon } from '/src/assets/icons/house.svg'
import { ReactComponent as BathIcon } from '/src/assets/icons/bath.svg'
import { ReactComponent as WebIcon } from '/src/assets/icons/web.svg'
import { ReactComponent as PhoneIcon } from '/src/assets/icons/phone.svg'
import { ReactComponent as PlusIcon } from '/src/assets/icons/plus.svg'
import { ReactComponent as TrashIcon } from '/src/assets/icons/trashcan.svg'
import { ReactComponent as ShareIcon } from '/src/assets/icons/share.svg'
import { ReactComponent as CaseIcon } from '/src/assets/icons/case.svg'
import { ReactComponent as InfoIcon } from '/src/assets/icons/info.svg'
import { ReactComponent as ExitIcon } from '/src/assets/icons/exit.svg'
import { ReactComponent as MessageIcon } from '/src/assets/icons/message.svg'
import { ReactComponent as MessageActiveIcon } from '/src/assets/icons/message-active.svg'
import { ReactComponent as NotificationIcon } from '/src/assets/icons/bell.svg'
import { ReactComponent as NotificationActiveIcon } from '/src/assets/icons/bell-active.svg'
import { ReactComponent as MessageUnreviewedIcon } from '/src/assets/icons/message-unreviewed.svg'
import { ReactComponent as MessageViewedIcon } from '/src/assets/icons/message-viewed.svg'
import { ReactComponent as AttachIcon } from '/src/assets/icons/clip.svg'
import { ReactComponent as HeadphonesIcon } from '/src/assets/icons/headphones.svg'
import { ReactComponent as OverseasIcon } from '/src/assets/icons/overseas.svg'
import { ReactComponent as CommerceIcon } from '/src/assets/icons/commerce.svg'
import { ReactComponent as DashedFilterIcon } from '/src/assets/icons/dash-filter.svg'
import { ReactComponent as MoreOptionDotsIcon } from '/src/assets/icons/moreOptions_dots.svg';
import { ReactComponent as VerifyCheckmarkIcon } from '/src/assets/icons/verifyCheckmark.svg'
import { ReactComponent as ClockIcon } from '/src/assets/icons/clock.svg'
import { ReactComponent as CloseIcon } from '/src/assets/icons/close.svg';
import { ReactComponent as HexagonIcon } from '/src/assets/icons/hexagon.svg';
import { ReactComponent as EditIcon } from '/src/assets/icons/edit.svg'
import { ReactComponent as BlockquoteIcon } from '/src/assets/icons/blockquote.svg'
import { ReactComponent as BoldIcon } from '/src/assets/icons/bold.svg'
import { ReactComponent as CodeIcon } from '/src/assets/icons/code.svg'
import { ReactComponent as UnorderedlistIcon } from '/src/assets/icons/desc-list.svg'
import { ReactComponent as OrderedListIcon } from '/src/assets/icons/ordered-list.svg'
import { ReactComponent as UnderlineIcon } from '/src/assets/icons/underline.svg'
import { ReactComponent as ItalicFontIcon } from '/src/assets/icons/italic-font.svg'
import { ReactComponent as H3Icon } from '/src/assets/icons/h3.svg'
import { ReactComponent as H4Icon } from '/src/assets/icons/h4.svg'
import { ReactComponent as Flag } from '/src/assets/icons/flag.svg'
import { ReactComponent as Tiktok } from '/src/assets/icons/tiktok.svg'
import { ReactComponent as Instagram } from '/src/assets/icons/instagram.svg'
import { ReactComponent as SwitchIcon } from '/src/assets/icons/switch.svg'
import { ReactComponent as InfoSquareIcon } from '/src/assets/icons/info-square.svg'

import { nearbyPlacesIcons } from "./NearbyPlacesIcons";

export const icons = {
  'x': X,
  'facebook': Facebook,
  'linkedin': LinkedIn,
  'logo-min': LogoMin,
  'search': SearchIcon,
  'arrow': Arrow,
  'arrow-prev': ArrowPrev,
  'arrow-next': ArrowNext,
  'arrow-back': ArrowBack,
  'star': Star,
  'close': CloseIcon,
  'checkmarkCircle': CheckmarkCircle,
  'checkmarkCircleSm': CheckmarkCircleSm,
  'x-markCircle': XMarkCircle,
  'comparison': Scales,
  'comparison-active': ScalesActive,
  'heart': Heart,
  'bed': Bed,
  'roomSquare': RoomSquare,
  'reviewStar': ReviewStar,
  'shaftArrow': ArrowIcon,
  'pounds': PoundsIcon,
  'schedule': ScheduleIcon,
  'filter': FilterIcon,
  'checkmark': CheckmarkIcon,
  'map': MapIcon,
  'residential': HouseIcon,
  'bath': BathIcon,
  'web': WebIcon,
  'phone': PhoneIcon,
  'plus': PlusIcon,
  'share': ShareIcon,
  'trashcan': TrashIcon,
  'case': CaseIcon,
  'exit': ExitIcon,
  'info': InfoIcon,
  'message': MessageIcon,
  'message-active': MessageActiveIcon,
  'notification': NotificationIcon,
  'notification-active': NotificationActiveIcon,
  'message-unreviewed': MessageUnreviewedIcon,
  'message-viewed': MessageViewedIcon,
  'attach': AttachIcon,
  'headphones': HeadphonesIcon,
  'overseas': OverseasIcon,
  'commerce': CommerceIcon,
  'dashedFilter': DashedFilterIcon,
  'more-option_dots': MoreOptionDotsIcon,
  'verify-checkmark': VerifyCheckmarkIcon,
  'clock': ClockIcon,
  'hexagon': HexagonIcon,
  'edit': EditIcon,
  'descList': UnorderedlistIcon,
  'blockquote': BlockquoteIcon,
  'bold': BoldIcon,
  'code': CodeIcon,
  'orderedList': OrderedListIcon,
  'underline': UnderlineIcon,
  'italic-font': ItalicFontIcon,
  'h3': H3Icon,
  'h4': H4Icon,
  'flag': Flag,
  'tiktok': Tiktok,
  'instagram': Instagram,
  'switch': SwitchIcon,
  'info-square': InfoSquareIcon,
  ...nearbyPlacesIcons,
} as const;

type SvgIconProps = SVGProps<SVGSVGElement> & { type: keyof typeof icons };

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null;
  return Icon && <Icon {...svgProps} />;
};
