import { FC, PropsWithChildren, useEffect, useState } from "react"
import { Typography } from "antd"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router"

import { VSpace } from "../../../ui/VSpace/VSpace"
import { useCustomBreakpoint } from "../../../../hooks/useCustomBreakpoint"
import { SearchInput } from "../../../elements"
import {
  useUpdateUseOnlineStatusMutation,
} from "../../../../graphql"
import "./MessagesBlock.less"
import { useAuth } from "src/context/AuthProvider"

export const MessagesBlock: FC<PropsWithChildren> = ({ children }) => {
  const { isDesktop } = useCustomBreakpoint()
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth()

  const [searchValue, setSearchValue] = useState<string>('')
  const [updateUserOnlineStatus] = useUpdateUseOnlineStatusMutation()

  const [isOnline, setIsOnline] = useState(() => {
    return sessionStorage.getItem('isOnline') === 'true';
  });

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      sessionStorage.setItem('isOnline', 'true');
    };

    const handleOffline = () => {
      setIsOnline(false);
      sessionStorage.setItem('isOnline', 'false');
    };

    if (navigator.onLine) {
      handleOnline();
    } else {
      handleOffline();
    }

    const onlineHandler = () => handleOnline();
    const offlineHandler = () => handleOffline();

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    const handleBeforeUnload = () => {
      setIsOnline(false);
      sessionStorage.setItem('isOnline', 'false');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const updateUserStatus = async () => {
      try {
        await updateUserOnlineStatus({
          variables: { id: user?.id ?? '', isOnline }
        });
      } catch (error) {
        console.error('Error updating user online status:', error);
      }
    };

    updateUserStatus();
  }, [user?.id, isOnline, updateUserOnlineStatus]);

  const updateUrlQuery = (value: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('search', value);
    navigate(`?${searchParams.toString()}`);
  };

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
    updateUrlQuery(value);
  };

  return (
    <aside className={'messages-aside'}>
      <VSpace size={0}>
        <VSpace size={24} className={'messages-aside-search'}>
          <Typography.Text className={isDesktop ? 'heading-5' : 'heading-6'}>
            {'Messages'}
          </Typography.Text>
          <SearchInput
            value={searchValue}
            onChange={handleSearchChange}
          />
        </VSpace>

        <VSpace size={32} className={'messages-aside-cards'}>
          {children}
        </VSpace>
      </VSpace>
    </aside>
  )
}
