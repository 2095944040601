import { ReactComponent as BallIcon } from '/src/assets/icons/volleyball.svg'
import { ReactComponent as CoinIcon } from '/src/assets/icons/coin.svg'
import { ReactComponent as CourtIcon } from '/src/assets/icons/court.svg'
import { ReactComponent as TreeIcon } from '/src/assets/icons/tree.svg'
import { ReactComponent as HospitalIcon } from '/src/assets/icons/hospital.svg'
import { ReactComponent as RestaurantIcon } from '/src/assets/icons/restaurant.svg'

import { ReactComponent as DefaultIcon } from '/src/assets/icons/location.svg'

export const nearbyPlacesIcons = {
  'sport': BallIcon,
  'school': CourtIcon,
  'secondary_school': CourtIcon,
  'atm': CoinIcon,
  'establishment': CourtIcon,
  'park': TreeIcon,
  'fitness_center': BallIcon, 
  'gym': BallIcon,
  'hospital': HospitalIcon,
  'restaurant': RestaurantIcon,
  'default': DefaultIcon,
  'sandwich_shop': RestaurantIcon,
  'steak_house': RestaurantIcon
}