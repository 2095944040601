import { Col, Image, Row } from "antd"
import { FC, useEffect, useState } from "react"
import './Header.less'

import { Logo } from "src/components/logo"

import { useGetAllChatsQuery, useRolesQuery, useUpdateUserMutation } from "src/graphql"
import { ButtonType, CustomButton } from "src/components/ui/CustomButton"
import { ReactComponent as BurgerIcon } from '/src/assets/icons/BurgerMenu.svg'
import { getAuthToken } from "src/helpers/auth"
import { useApp } from "src/components/app"
import { CreateNewListModal } from "src/components/favoriteLists/CreateList"
import { AllFavoriteListsModal } from "src/components/favoriteLists/AllLists"
import { UserMenu } from "./UserMenu"
import { UserMenuDrawer } from "./UserMenuDrawer"
import { NavigateOptions, useLocation, useNavigate } from "react-router"
import { useCustomBreakpoint } from "src/hooks/useCustomBreakpoint"
import { FeaturesMenuInterface, featureMenus, navigationMenus } from "../../headerNavigation"
import { useAuth } from "src/context/AuthProvider"
import { FeaturesNavigation } from "src/components/menu/FeaturesNavigation"
import Navigation from "src/components/menu/PagesNavigation/Navigation"
import { Link } from "react-router-dom"
import { GlobalRoutes } from "src/pages/routesEums"
import { IconButton } from "src/components/ui/IconButton"
import { AvatarPlaceholder } from "src/components/ui/AvatarPlaceholder"
import { UserRoleType } from "src/types/global"
import { NavigationDrawer } from "./NavigationDrawer"

export type ModalType = 'createList' | 'lists'

const Header: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { user, role } = useAuth()

  const navigate = useNavigate()

  const options: NavigateOptions = {
    replace: true
  }

  const token = getAuthToken()
  const [modalType, setModalType] = useState<ModalType>('lists')
  const [compItemsLength, setCompItemsLength] = useState<Maybe<string>>(localStorage.getItem('comparison_ids'))
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)

  const userId = user?.id

  const chats = useGetAllChatsQuery({
    variables: {
      filters: {
        or: [
          { client: {id: {eq: userId}}},
          {agency: {id: {eq: userId}}}
        ],
        messages: {isNewMessage: {eq: true}, receiver: {id: {eq: userId}}}}
    },
    fetchPolicy: 'network-only',
    pollInterval: 4000,
  })

  useEffect(() => {
    if (isUserMenuOpen && !isDesktop) {
      document.body.style.overflow = "hidden"
    }

    return () => {
      document.body.style.overflow = "unset"
    }
  }, [isUserMenuOpen])

  const newMessagesCount = chats?.data?.chats?.data?.flatMap(it => it?.attributes?.messages?.data)?.filter(it => it?.attributes?.isNewMessage)?.length ?? 0
  const comparisonCount = JSON.parse(compItemsLength || '[]').length
  
  const location = useLocation()

  const { isPropertyListsModalOpen, openSaveToListModal } = useApp().app
  const { isDesktop } = useCustomBreakpoint()

  const { userMenuItems, agencyMenuItems, adminMenuItems } = navigationMenus
  const { agencyFeaturesMenu, userFeaturesMenu, adminFeaturesMenu } = featureMenus

  const userRole = role === 'user'

  const currentUserHeaderMenu = location.pathname.includes('dashboard') && userRole 
    ? userMenuItems.userDashboardMenu 
    : userMenuItems.defaultMenu
  
  const headerNavigationMap: Record<string, ComponentUiLink[]> = {
    'user': currentUserHeaderMenu,
    'agency': agencyMenuItems.agencyMenu,
    'admin': adminMenuItems.adminMenu,
    'owner': adminMenuItems.adminMenu,
    'undefined': userMenuItems.defaultMenu
  } 

  const featuresMenuMap: Record<string, FeaturesMenuInterface[]> = {
    'user': userFeaturesMenu,
    'agency': agencyFeaturesMenu,
    'admin': adminFeaturesMenu,
    'owner': adminFeaturesMenu,
  };

  const headerNavigation = headerNavigationMap[role || 'undefined']
  const featuresMenu = featuresMenuMap[role || 'undefined']
  
  const handleUserDropdownMenu = () => (
    setIsUserMenuOpen(prev => !prev)
  )

  useEffect(() => {
    const storageChangeHandler = (event: StorageEvent) => {
      if (event.key === 'comparison_ids') {
        setCompItemsLength(event.newValue)
      }
    }

    window.addEventListener("storage", storageChangeHandler)

    return () => {
      window.removeEventListener("storage", storageChangeHandler)
    }
  }, []);

  const modalTypes = {
    'createList': <CreateNewListModal 
      toggleModal={openSaveToListModal} 
      setModalType={setModalType} 
      isModalOpen={isPropertyListsModalOpen} 
    />,
    'lists': <AllFavoriteListsModal 
      toggleModal={openSaveToListModal} 
      setModalType={setModalType} 
      isModalOpen={isPropertyListsModalOpen} 
    />
  }

  const headerAvatar = role === 'agency'
   ? user?.agency?.data?.attributes?.agencyLogo?.data?.attributes?.url
   : user?.avatar?.data?.attributes?.url

  const { data: roles } = useRolesQuery()
  const [updateUser] = useUpdateUserMutation()
  
  const roleToSwitch = role === UserRoleType.Agency ? UserRoleType.User : UserRoleType.Agency

  const roleId = roles?.usersPermissionsRoles?.data.find(role => role.attributes?.name.toLowerCase() === roleToSwitch)
    
  const switchRole = async () => {
    if (!user?.id) {
      return
    }

    await updateUser({ 
      variables: {
        id: user?.id,
        data: {
          role: roleId?.id
        }
      } 
    })
  }


  const handleSwitchRole = () => {
    switchRole()
    window.location.replace('/')
  }

  return (
    <Row className={'header'} wrap={false}>
      <Col className={'header-logo-wrapper'} span={3}>
        {isDesktop ? <Logo classname={'header-logo'} /> : <BurgerIcon onClick={() => setDrawerOpen(prev => !prev)} />}
      </Col>

      {isDesktop && (
        <Col>
          <Navigation
            menu={headerNavigation}
          />
        </Col>
      )}


      {token && (
        <Col className={'header-user-features-navigation'}>
          <FeaturesNavigation 
            comparisonItemsLength={comparisonCount}
            newMessagesCount={newMessagesCount}
            features={featuresMenu} 
          />
        </Col>
      )}

      {!token && <Col className={'header-auth-buttons-wrapper'}>
        <Link to={GlobalRoutes.Comparison} style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton 
            style={{ border: 'none', boxShadow: 'none' }}
            iconType={'comparison'}
          />
        </Link>
          
        <Link 
          to={GlobalRoutes.SignIn}
          state={{ from: location.pathname }}
        >
          <CustomButton 
            buttonType={ButtonType.TRANSPARENT} 
            buttonText={'Log In'} 
          />
        </Link>

        <Link 
          to={GlobalRoutes.SignUp}
          state={{ from: location.pathname }}
        >
          <CustomButton 
            type={'button'} 
            buttonType={ButtonType.PRIMARY} 
            buttonText={'Sign Up'} 
          />
        </Link>
      </Col>}

      {token && (
        <Col className={'user-avatar-wrapper'}>
          {headerAvatar?.length 
            ? <Image
                height={38}
                onClick={handleUserDropdownMenu}
                onMouseEnter={isDesktop ? handleUserDropdownMenu : undefined}
                preview={false}
                className={'user-avatar'}
                src={headerAvatar}
              />
            : <AvatarPlaceholder 
                onClick={handleUserDropdownMenu}
                onMouseEnter={isDesktop ? handleUserDropdownMenu : undefined}
                firstName={user?.firstName} 
                lastName={user?.lastName} 
              />
          }
        </Col>
      )}

      {modalTypes[modalType]}

      {isDesktop
        ? <UserMenu onRoleSwitch={handleSwitchRole} onOpen={setIsUserMenuOpen} isOpen={isUserMenuOpen} />
        : <UserMenuDrawer onRoleSwitch={handleSwitchRole} isOpen={isUserMenuOpen} onOpen={setIsUserMenuOpen} />
      }

      <NavigationDrawer open={drawerOpen} onClose={setDrawerOpen} menu={headerNavigation} />
    </Row>
  )
}

export { Header }
