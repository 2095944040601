import classNames from "classnames"
import { FC } from "react"
import { NavLink } from "react-router-dom"
import './index.less'
import { GlobalRoutes } from "src/pages/routesEums"

type Props = {
  menu: ComponentUiLink[]
  onMenuClose: () => void
}

export const MobileNavigation: FC<Props> = ({ menu, onMenuClose }) => {
  return (
    <nav className={'mobile-navigation'}>
      <ul className={'mobile-navigation-list'}>
        <li
          className={'mobile-navigation-list-item'}
          onClick={onMenuClose}
        >
          <NavLink
            to={GlobalRoutes.Home}
            className={(props) => {
              return classNames(
                'mobile-navigation-item-link',
                'paragraph-md',
                { 'active-link': props.isActive },
                { 'label-md': props.isActive }
              );
            }}
          >
            Home
          </NavLink>
        </li>
          
        {menu?.map((item, index) => (
          <li 
            key={item.id} 
            className={'mobile-navigation-list-item'}
            onClick={onMenuClose}
          >
            <NavLink
              to={item.url}
              className={(props) => {
                return classNames(
                  'mobile-navigation-item-link',
                  'paragraph-md',
                  { 'active-link': props.isActive },
                  { 'label-md': props.isActive }
                );
              }}
            >
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}