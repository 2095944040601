import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useConfirmEmailMutation, useLoginMutation, useMeLazyQuery, useRegisterMutation, useRolesQuery, useUpdateUserMutation } from '../graphql';
import { removeAuthToken, setAuthToken } from '../helpers/auth';

export interface LoginFields {
  email: string;
  password: string;
  source?: string
}
export type RegistrationFields = 'username' | 'email' | 'password';

type AuthProviderLogin = (fields: LoginFields) => Promise<void>;
type AuthProviderRegistration = (fields: Record<RegistrationFields, string>) => Promise<void>;

type AuthContextProps = {
  user: MeQuery['me'];
  role: string | null | undefined
  login: AuthProviderLogin;
  register: AuthProviderRegistration;
  logout: () => Promise<void>;
  ableToSwitchRole: Maybe<boolean>
  getUser: () => void;
  confirmEmail: (code: string) => Promise<any>;
  refetchUser: () => void;
  loading: boolean
};

type AuthProviderProps = PropsWithChildren<Partial<AuthContextProps>>;

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [getMe, { data: me, error, loading: userLoading, refetch }] = useMeLazyQuery({ fetchPolicy: 'no-cache' });
  const [loginMutation, { loading: regLoading }] = useLoginMutation();
  const [registerMutation, { loading: loginLoading }] = useRegisterMutation();
  const [confirm, { loading: confirmationLoading }] = useConfirmEmailMutation()
    
  const user = error ? null : me?.me;
  const loading = userLoading || regLoading || loginLoading || confirmationLoading;
  const ableToSwitchRole = user?.isAbleToSwitchRole;
  const role = user?.role?.data?.attributes?.name.toLowerCase();

  useEffect(() => {
    getMe();
  }, []);
  
  const login = async ({ email, password, source }: LoginFields) => {
    await loginMutation({
      variables: {
        input: {
          identifier: email,
          password: password,
        },
      },
    }).then((result) => {
      if (result.data?.login.jwt) {
        window.location.replace(source || '/');
        setAuthToken(result.data.login.jwt);
        refetch()
      }
    });
  };

  const confirmEmail = async (token: string): Promise<any> => {
    if (token) {
      try {
        const result = await confirm({
          variables: {
            confirmation: token
          }
        });

        if (result.data?.emailConfirmation?.jwt) {
          setAuthToken(result.data?.emailConfirmation?.jwt);
          sessionStorage.setItem('confirmationStepFinished', 'true');
          window.location.reload();
        }
      } catch (error) {
        console.error('Error confirming email:', error);

        return error
      }
    }
  }

  const register = async ({ email, username, password }: Record<RegistrationFields, string>) => {
    await registerMutation({
      variables: {
        input: { email, password, username: username },
      },
    }).then((result) => {
      if (result?.data?.register?.user?.email) {
        localStorage.setItem('email', result.data.register.user.email);
      }
    })
  };

  const logout = async () => {
    removeAuthToken();
    getMe({ context: { headers: { authorization: undefined } } });
    window.location.replace('/');
  };


  return (
    <AuthContext.Provider
      value={{
        user,
        role,
        ableToSwitchRole,
        loading,
        login,
        logout,
        confirmEmail,
        register,
        refetchUser: () => { refetch() },
        getUser: () => { getMe() }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};