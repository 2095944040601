import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { useNotificationsQuery } from "src/graphql";
import { useSocket } from "src/socket/useSocket";
import { useAuth } from "./AuthProvider";
import { ApolloQueryResult } from "@apollo/client";

type AppNotifications = {
  appNotifications: NotificationsQuery | undefined
  notificationsCount: number
  refetch: () => Promise<ApolloQueryResult<NotificationsQuery>>
}

type AppNotificationsProviderProps = PropsWithChildren<Partial<AppNotifications>>;

const NotificationsContext = createContext<AppNotificationsProviderProps | undefined>(undefined)

export const useNotificationsContext = () => {
  const context =  useContext(NotificationsContext)
  if (!context) {
    throw new Error('useNotifications must be used within an NotificationsProvider');
  }

  return context
}

export const NotificationsProvider: FC<AppNotificationsProviderProps> = ({ children }) => {
  const [newNotificationsCount, setNewNotificationsCount] = useState(0)
  const socket = useSocket()

  const { user } = useAuth()

  const { data, refetch } = useNotificationsQuery({
    variables: {
      filters: {
        users: { id: { eq: user?.id } },
      }
    }
  })

  useEffect(() => {
    const count = data?.notifications?.data.filter((notification) => notification?.attributes?.isNew).length
    setNewNotificationsCount(count ?? 0)
  }, [data?.notifications?.data])

  socket.on('requestCreated', (data) => {
    refetch()
  })

  socket.on('requestUpdated', (data) => {
    refetch()
  })

  const handleRefetch = async () => (
    await refetch()
  )

  return (
    <NotificationsContext.Provider 
      value={{ 
        appNotifications: data,
        notificationsCount: newNotificationsCount,
        refetch: handleRefetch
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
